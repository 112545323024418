import React from 'react';
import PropTypes from 'prop-types';
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Space } from 'antd';

const DropdownComponent = ({title,items}) => (
  <Dropdown
    menu={{
        items,
    }}
  >
    <a style={{color:"rgb(92, 122, 147)"}} onClick={(e) => e.preventDefault()}>
      <Space>
        {title}
        <DownOutlined />
      </Space>
    </a>
  </Dropdown>
);
DropdownComponent.propTypes = {  
  title: PropTypes.string.isRequired,
  items: PropTypes.array,
};

export default DropdownComponent;