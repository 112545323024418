import Constant from '../Constants/Constants';
  
  const initialState = {
    user: null,
    role: null,
    loading: false,
    collapsed: false,
    error: null,
  };
  
  const authReducer = (state = initialState, action) => {
    switch (action.type) {
      case Constant.LOGIN_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case Constant.LOGIN_SUCCESS:
        return {
          ...state,
          user: action.payload,
          token:action.payload?.jwtToken,
          role: "Admin",
          loading: false,
        };
      case Constant.LOGIN_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      case Constant.LOGOUT:
        return {
          ...state,
          user: null,
          role: null,
        };
        case Constant.IS_SIDEBAR:
      return {
        ...state,
        collapsed: !state.collapsed,
      };
      case Constant.SET_USER_ROLE:
        return {
          ...state,
          role: action.payload,
        };
      default:
        return state;
    }
  };
  export class Auth{
    static detailsReducer = (state = initialState, action) => {
        switch (action.type) {
            case Constant.GET_DETAILS_REQUEST:
                return {
                  ...state,
                  loading: true,
                  error: null,
                };
          case Constant.GET_DETAILS_SUCCESS:
            return {
              ...state,
              userDetails: action.payload,
              error: null,
            };
          case Constant.GET_DETAILS_FAILURE:
            return {
              ...state,
              error: action.payload,
            };
          default:
            return state;
        }
      };
  }
  export default authReducer;

  