/* eslint-disable */
import { observer } from "mobx-react-lite";
import { ReactElement, useState } from "react";
import * as React from "react";
import { useStore } from "../../../../stores/root-store-context";
import Note, { NoteType } from "./Note";
import { Button, Col, Pagination, Row, Select } from "antd";
import { PlusOutlined } from "@ant-design/icons";

const Notes = observer((props: {}): ReactElement => {
  const { ricStore, applicationStore } = useStore();
  const numEachPage = 10;
  const [isCreatingNew, setIsCreatingNew] = useState<boolean>(false);
  const [current, setCurrent] = useState<number>(1);
  const [minValue, setMinValue] = useState<number>(0);
  const [maxValue, setMaxValue] = useState<number>(10);
  const [typeFilter, setTypeFilter] = useState<string>("All");
  const [categoryFilter, setCategoryFilter] = useState<string>("All");
  const handleChange = (value: number): void => {
    setCurrent(value);
    setMinValue((value - 1) * numEachPage);
    setMaxValue(value * numEachPage);
  };

  return (
    <div style={{ padding: "10px" }}>
      <Row style={{ marginBottom: 10 }}>
        <Col span={3}>
          <b>Type</b>
        </Col>
        <Col span={9}>
          <Select
            defaultValue={typeFilter}
            style={{ width: 300, marginBottom: 10 }}
            onChange={(value: string) => {
              setTypeFilter(value);
              handleChange(1);
            }}
            options={[
              { value: "All", label: "All" },
              ...[
                ...ricStore.notesInformation.reduce(
                  (types, obj) => (types.add(obj.Type), types),
                  new Set<string>()
                ),
              ].map((el, index) => ({ value: el, label: el })),
            ]}
          />
        </Col>
        <Col span={3}>
          <b>Category 1</b>
        </Col>
        <Col span={9}>
          <Select
            defaultValue={categoryFilter}
            style={{ width: 300, marginBottom: 10 }}
            onChange={(value: string) => {
              setCategoryFilter(value);
              handleChange(1);
            }}
            options={[
              { value: "All", label: "All" },
              ...ricStore.category1Items
                .filter((category1) => {
                  return ricStore.notesInformation.some(
                    (note) => note.category1 === category1
                  );
                })
                .map((el, index) => {
                  return { value: el, label: el };
                }),
            ]}
          />
        </Col>
      </Row>
      <Row style={{ marginBottom: 10 }}>
        <Col span={24}>
          <Button
            disabled={isCreatingNew || !ricStore.isEditable}
            type="primary"
            shape="circle"
            icon={<PlusOutlined />}
            onClick={() => {
              setIsCreatingNew(true);
              ricStore
                .createNote(
                  ricStore.accountInformation.accountNumber,
                  applicationStore.userEmployee.employeeKey.toString(),
                  (status: boolean) => {
                    setIsCreatingNew(status);
                  }
                )
                .catch(console.log);
            }}
          />{" "}
          <span color="gray">
            {isCreatingNew ? "Please wait" : "Click to Add a Note"}
          </span>
        </Col>
      </Row>
      {ricStore.notesInformation
        .filter((el) => {
          return (
            (typeFilter === "All" || el.Type === typeFilter) &&
            (categoryFilter === "All" || el.category1 === categoryFilter)
          );
        })
        ?.slice(minValue, maxValue)
        ?.map((item: NoteType, index: number) => {
          return (
            <Note
              key={item.NoteID}
              item={item}
              background={index % 2 ? "#ffffff" : "#f2f2f2"}
            />
          );
        })}
      <div style={{ paddingTop: 10 }}>
        <Pagination
          showSizeChanger={false}
          defaultCurrent={1}
          current={current}
          onChange={handleChange}
          total={
            ricStore.notesInformation.filter((el) => {
              return (
                (typeFilter === "All" || el.Type === typeFilter) &&
                (categoryFilter === "All" || el.category1 === categoryFilter)
              );
            })?.length
          }
        />
      </div>
    </div>
  );
});
export default Notes;
