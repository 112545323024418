/* eslint-disable */
import { observer } from "mobx-react-lite";
import { ReactElement, useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  Upload,
  UploadProps,
} from "antd";
import { useStore } from "../../../stores/root-store-context";
import { getPhoneFormat } from "../helpers/phoneFormat";
import { mergeWithDefault } from "../helpers/searchHelper";
import ListAdvancedSearch from "./ListAdvancedSearch";

export type ISearchItem = {
  account?: number | null;
  community?: string | null;
  communityKey?: number | null;
  blockLot?: string | null;
  name?: string | null;
  address?: string | null;
  phone?: string | null;
  email?: string | null;
  priority?: boolean | null;
};
const stylesRic = {
  container: {
    display: "flex",
    justifyContent: "center",
    flexFlow: "row wrap",
  },
  formField: {
    height: "38px",
    maxHeight: "38px",
    fontSize: "1rem",
    margin: "4px",
  },
  buttonReset: {
    minWidth: 110,
    flex: "0 1 110px",
    backgroundColor: "#8c8c8c",
    borderRadius: 10,
    padding: 0,
  },
};
const AdvancedSearch = observer((props: {}): ReactElement => {
  const { ricStore, applicationStore } = useStore();
  console.log(applicationStore,"applicationStoreapplicationStoreapplicationStore")
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [searchItem, setSearchItem] = useState<ISearchItem>({});
  const [resultItems, setResultItems] = useState<ISearchItem[]>();

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  const [errors, setErrors] = useState<Record<string, boolean>>({});

  const onReset = (): void => {
    setSearchItem({});
    setResultItems(undefined);
    form.setFieldValue("account", "");
    form.setFieldValue("blockLot", "");
    form.setFieldValue("name", "");
    form.setFieldValue("address", "");
    form.setFieldValue("phone", "");
    form.setFieldValue("email", "");
  };

  const dismissModal = (): void => {
    onReset();
    setIsOpenModal(false);
  };
  const openModal = (): void => {
    setIsOpenModal(true);
  };

  const isError = (): boolean => {
    for (const key in errors) {
      if (errors[key]) {
        return true;
      }
    }
    return false;
  };
  const isEmpty = (): boolean => {
    // eslint-disable-next-line guard-for-in
    for (const key in searchItem) {
      const fieldKey = key as keyof ISearchItem;
      if (
        typeof searchItem[fieldKey] === "string" ||
        typeof searchItem[fieldKey] === "number"
      ) {
        const find = searchItem[fieldKey];
        if (find && find.toString().length > 0) {
          return false;
        }
      }
    }
    return true;
  };

  function filterItems(
    searchItems: ISearchItem[],
    properties: number[]
  ): ISearchItem[] {
    return searchItems.filter((item) =>
      properties.includes(item.communityKey || 0)
    );
  }

  const filterSearchItems = (
    items: ISearchItem[],
    filter: ISearchItem
  ): ISearchItem[] => {
    return items.filter((item) => {
      // eslint-disable-next-line guard-for-in
      for (const key in filter) {
        const fieldKey: keyof ISearchItem = key as keyof ISearchItem;
        if (filter[fieldKey] !== undefined) {
          if (
            typeof filter[fieldKey] === "string" ||
            typeof filter[fieldKey] === "number"
          ) {
            const currentItemString: string | undefined = item[fieldKey]
              ? item[fieldKey]?.toString().toLocaleLowerCase()
              : "";
            const searchItem =
              filter[fieldKey]?.toString()?.toLocaleLowerCase() || "";
            if (!currentItemString?.includes(searchItem)) {
              return false;
            }
          }
          if (typeof filter[fieldKey] === "boolean") {
            if (
              item[key as keyof ISearchItem] !==
              filter[key as keyof ISearchItem]
            ) {
              return false;
            }
          }
        }
      }
      return true;
    });
  };
  const onSearch = (itemSearch: ISearchItem): void => {
    ricStore
      .searchAccountInformation(itemSearch)
      .then((res) => {
        const filteredSearchItems = filterItems(
          res.data,
          applicationStore.PropertieList.map((el) => el.id)
        );
        const items = filterSearchItems(
          filteredSearchItems,
          mergeWithDefault(itemSearch)
        );
        setResultItems(items);
        setIsLoading(false);
      })
      .catch((error: {}) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const onSearchLocal = async () => {
    await setIsLoading(true);
    await setResultItems([]);
    await onSearch(searchItem);
  };

  const onChange = (target: EventTarget & HTMLInputElement, value: any) => {
    setErrors((prevState) => ({
      ...prevState,
      [target.name]: !target.checkValidity(),
    }));
    // setItemSearch((prev) => {
    //   return {
    //     ...prev,
    //     [target.name as keyof ISearchItem]:
    //       value as ISearchItem[keyof ISearchItem],
    //   };
    // });
  };

  return (
    <>
      <Button onClick={openModal}>Advanced Search</Button>
      <Modal
        title={<h2 style={{ textAlign: "center" }}>Advanced Search</h2>}
        style={{ height: "90%", padding: 0 }}
        width={900}
        open={isOpenModal}
        footer={null}
        onCancel={() => {
          dismissModal();
        }}
      >
        <Spin spinning={isLoading}>
          <Form
            form={form}
            variant="outlined"
            layout="vertical"
            initialValues={searchItem}
            labelCol={{ span: 24 }}
            style={{
              width: "100%",
              padding: 8,
            }}
            onValuesChange={(changedValues, allValues) => {
              setSearchItem(allValues);
            }}
            // validateMessages={validateMessages}
            // requiredMark={customizeRequiredMark}
            autoComplete="off"
            scrollToFirstError={{ block: "center", inline: "nearest" }}
          >
            <Row>
              <Col span={5}>
                <Form.Item<ISearchItem>
                  name="account"
                  style={stylesRic.formField}
                >
                  <Input
                    style={stylesRic.formField}
                    placeholder="Account #"
                    onChange={(event) => {
                      onChange(event.target, event.target.value);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item<ISearchItem>
                  name="blockLot"
                  style={stylesRic.formField}
                >
                  <Input
                    style={stylesRic.formField}
                    placeholder="Block/Lot #"
                    onChange={(event) => {
                      onChange(event.target, event.target.value);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={11}>
                <Form.Item<ISearchItem> name="name" style={stylesRic.formField}>
                  <Input
                    style={stylesRic.formField}
                    placeholder="Name"
                    onChange={(event) => {
                      onChange(event.target, event.target.value);
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={7}>
                <Form.Item<ISearchItem>
                  name="address"
                  style={stylesRic.formField}
                >
                  <Input
                    style={stylesRic.formField}
                    placeholder="Property Address"
                    onChange={(event) => {
                      onChange(event.target, event.target.value);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item<ISearchItem>
                  name="phone"
                  style={stylesRic.formField}
                  rules={[
                    {
                      required: false,
                      message: "Please input your daytime phone!",
                      min: 12,
                    },
                  ]}
                >
                  <Input
                    style={stylesRic.formField}
                    placeholder="Phone #"
                    onChange={(event) => {
                      const { value } = event.target;
                      const formattedValue = getPhoneFormat(value);
                      form.setFieldsValue({
                        phone: formattedValue,
                      });
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item<ISearchItem>
                  name="email"
                  style={stylesRic.formField}
                >
                  <Input
                    style={stylesRic.formField}
                    placeholder="Email"
                    pattern="[^@]+@[^@]+\.[a-zA-Z]{2,6}"
                    onChange={(event) => {
                      onChange(event.target, event.target.value);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item<ISearchItem> style={{ marginLeft: 5, marginTop: 8 }}>
                  <Button
                    size="middle"
                    type="primary"
                    htmlType="submit"
                    disabled={isError() || isEmpty()}
                    onClick={async () => {
                      await onSearchLocal();
                    }}
                  >
                    Search
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          {resultItems !== undefined ? (
            <ListAdvancedSearch
              key={isLoading.toString()}
              callBackForItem={(item: ISearchItem) => {
                const property = applicationStore.PropertieList.find(
                  (_) => _.id === item.communityKey
                );
                applicationStore.setSelectedPropertiesById(
                  item.communityKey || 0
                );
                if (property)
                  applicationStore.setSearchTextProperty(
                    property.titleInDropdown
                  );
                ricStore.setAccount(item.account || 0);
                dismissModal();
              }}
              items={resultItems}
              isLoading={isLoading}
            />
          ) : (
            <></>
          )}
        </Spin>
      </Modal>
    </>
  );
});
export default AdvancedSearch;
