/* eslint-disable */
import { AutoComplete, Select } from "antd";
import { observer } from "mobx-react-lite";

import React, { useState } from "react";
import { useStore } from "../../../../stores/root-store-context";
import AdvancedSearch from "../AdvancedSearch";

const stylesRic = {
  container: {
    display: "flex",
    flexFlow: "row wrap",
    alignItems: "center",
  },
  formField: {
    height: "38px",
    maxHeight: "38px",
    fontSize: "1rem",
    margin: "4px",
  },
};
const SelectProperty = observer(() => {
  const { applicationStore, ricStore } = useStore();
  const [searchText, setSearchText] = useState<string>("");
  return (
    <div style={stylesRic.container}>
      <AutoComplete
        style={{
          ...{ minWidth: 150, maxWidth: 400, flex: "1 1 400px" },
          ...stylesRic.formField,
        }}
        searchValue={applicationStore.searchTextProperty}
        value={applicationStore.searchTextProperty}
        options={[
          {
            key: "All",
            value: "All",
            id: 0,
            label: "All",
          },
          ...applicationStore.PropertieList.map((item, index) => {
            return {
              key: item.id,
              value: item.titleInDropdown,
              id: item.id,
              label: item.titleInDropdown,
            };
          }),
        ]}
        placeholder={<b>Please enter property or All...</b>}
        filterOption={(inputValue, option) =>
          option!.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
        }
        onChange={(value) => {
          applicationStore.setSearchTextProperty(value);
        }}
        onSelect={(el, option) => {
          applicationStore.setSelectedPropertiesById(option.id);
          applicationStore.setSearchTextProperty(
            applicationStore.SelectedProperties.titleInDropdown
          );
          setSearchText(applicationStore.SelectedProperties.titleInDropdown);
          ricStore.setShowRicInfo(false);
          ricStore.resetRicInfo();
          ricStore.setAccount(undefined);
        }}
        onFocus={() => {
          applicationStore.setSearchTextProperty("");
        }}
        onBlur={() => {
          applicationStore.setSearchTextProperty(
            applicationStore.SelectedProperties.titleInDropdown
          );
        }}
      />
      {applicationStore.isAdvancedSearch ? <AdvancedSearch /> : <></>}
    </div>
  );
});

export default SelectProperty;
