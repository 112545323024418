import Constant from '../../Constants/Constants';
  
  const initialState = {
    GetAllCommunitys:[],
    getCommunityById:{},
    updateData:{},
    loading: false,
    error: null,
  };
 class Communitys{
    static GetAllCommunitysListReducer = (state = initialState, action) => {
        switch (action.type) {
            case Constant.GET_ALL_COMMUNITYS_REQUEST:
                return {
                  ...state,
                  loading: true,
                  error: null,
                };
          case Constant.GET_ALL_COMMUNITYS_SUCCESS:
            return {
              ...state,
              loading: false,
              getAllCommunitys: action.payload,
              error: null,
            };
          case Constant.GET_ALL_COMMUNITYS_FAILURE:
            return {
              ...state,
              error: action.payload,
              loading: false,
            };
            case Constant.GET_COMMUNITY_BYID_REQUEST:
                return {
                  ...state,
                  loading: true,
                  error: null,
                };
          case Constant.GET_COMMUNITY_BYID_SUCCESS:
            return {
              ...state,
              loading: false,
              getCommunityById: action.payload,
              error: null,
            };
          case Constant.GET_COMMUNITY_BYID_FAILURE:
            return {
              ...state,
              error: action.payload,
              loading: false,
            };
            case Constant.UPDATED_COMMUNITY_BYID_REQUEST:
                return {
                  ...state,
                  loading: true,
                  error: null,
                };
          case Constant.UPDATED_COMMUNITY_BYID_SUCCESS:
            return {
              ...state,
              loading: false,
              updateData: action.payload,
              error: null,
            };
          case Constant.UPDATED_COMMUNITY_BYID_FAILURE:
            return {
              ...state,
              error: action.payload,
              loading: false,
            };
            case Constant.STATUS_UPDATE_COMMUNITY_BYID_REQUEST:
              return {
                ...state,
                loading: true,
                error: null,
              };
        case Constant.STATUS_UPDATE_COMMUNITY_BYID_SUCCESS:
          return {
            ...state,
            loading: false,
            communityStatus: action.payload,
            error: null,
          };
        case Constant.STATUS_UPDATE_COMMUNITY_BYID_FAILURE:
          return {
            ...state,
            error: action.payload,
            loading: false,
          };
          case Constant.PERMISSION_UPDATE_COMMUNITY_BYID_REQUEST:
            return {
              ...state,
              loading: true,
              error: null,
            };
      case Constant.PERMISSION_UPDATE_COMMUNITY_BYID_SUCCESS:
        return {
          ...state,
          loading: false,
          PermissionStatus: action.payload,
          error: null,
        };
      case Constant.PERMISSION_UPDATE_COMMUNITY_BYID_FAILURE:
        return {
          ...state,
          error: action.payload,
          loading: false,
        };
            default:
                return state;
            }
          };
  }
  export default Communitys;