import { useEffect, useState } from "react";
import Component from "../../../Components";
import { EyeOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { Users } from "../../../redux/Actions";
import { Button, Col, Row } from "antd";
import TextInput from "Components/TextInput/TextInput";
import { useNavigate } from "react-router-dom";
import CommonFunctions from "utils/CommonFunction/CommonFunctions";
const { DynamicTable, Spinner } = Component;

const UsersList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
 
 
  const {loading,userDetails  } = useSelector((state) => state?.GetAllUser);
  const [userLists, setUserLists] = useState([]);
  
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    filter: "",
    total: 0,
  });

 
  const columns = [
    { title: "First Name", dataIndex: "firstName" },
    { title: "Last Name", dataIndex: "lastName" },
    { title: "Email Name", dataIndex: "email" },
    // { title: "Status", dataIndex: "status" },
    { title: "Modified ", dataIndex: "modified",render:(value)=>CommonFunctions.formatDate(value) },
    {
      title: "Actions",
      dataIndex: "user",
      render: (text, record) => renderAction(record),
    },
  ];
  const renderAction = (data) => {
    return (
      <>
        <Button className="custom-outlined-btn" 
          onClick={() => viewsCommunityHandle(data)}
          icon={<EyeOutlined />}
        >
        </Button>
      </>
    );
  };
  const fetchData = (current, pageSize, filter) => {
    const req = {
      pageNumber: current,
      pageSize: pageSize,
      filter: filter,
    };
    dispatch(Users.GetAllUsersRequest({ request: req }));
  };
  useEffect(() => {
    fetchData(pagination.current, pagination.pageSize);
  }, []);

  useEffect(() => {
    if (userDetails?.data?.usersList?.length > 0) {
      setUserLists(userDetails?.data?.usersList);
      setPagination({
        ...pagination,
        total:
        userDetails?.data?.totalRecords
        ,
      });
    } else {
      setUserLists([]);
    }
  }, [userDetails]);

  const viewsCommunityHandle = (user) => {
    navigate(`/AAM/user/userDetails/${user?.id}`);
  };

  const handleTableChange = (pagination) => {
    fetchData(pagination.current, pagination.pageSize);
    setPagination(pagination); 
  };
  const HandleOnChange = (e)=>{
    fetchData(pagination.current, pagination.pageSize,e?.target?.value);
}
const onRow = (record) => ({
  onClick: () => {
    navigate(`/AAM/user/userDetails/${record?.id}`);
  },
})
  return (
    <>
      {loading && <Spinner />}
     <div className="user-table-box">
     <Row justify={'end'} gutter={[16,16]}>
        <Col sm={3} >
        <TextInput name="filter"  
       placeholder="Search" className="search" icon={<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14 14L11.1 11.1M12.6667 7.33333C12.6667 10.2789 10.2789 12.6667 7.33333 12.6667C4.38781 12.6667 2 10.2789 2 7.33333C2 4.38781 4.38781 2 7.33333 2C10.2789 2 12.6667 4.38781 12.6667 7.33333Z" stroke="#757575" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
        }  onChange={HandleOnChange}/>
        </Col>
        <Col sm={2}>
        <Button onClick={()=>navigate('/AAM/user/CreateUser')}>Create User</Button>
        </Col>
      </Row>
      <DynamicTable onRow={onRow} data={userLists ?? []} columns={columns}  pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            total: pagination.total,
            showSizeChanger: true,
          }} 
          onChange={handleTableChange}/>
     </div>
          
{/* <Suggestion /> */}
    </>
  );
};
export default UsersList;
