/* eslint-disable */
import { Button, Flex, List, Select, Table } from "antd";
import { ReactElement, useEffect, useState } from "react";
import { InfinityLineField, InlineField } from "./AccountInfo/AccountInfoCard";
import { SearchOutlined } from "@ant-design/icons";
import Column from "antd/es/table/Column";
import { useStore } from "../../../stores/root-store-context";
import * as React from "react";
import { dateValidation } from "../helpers/dateValidation";

type ArchitecturalNote = {
    architecturalNoteKey: string;
    created: string;
    architecturalKey: string;
    employeeKey: number;
    note: string;
    entered: string;
    fileName: string | null;
    imageBase64: string | null;
    enteredStr: string;
    fileUrl: string | null;
};

export type ArchitecturalData = {
    ArchitecturalKey: string;
    a_Created: string;
    UnitKey: string;
    EmployeeKey: string;
    a_Received: string;
    a_Status: string;
    a_Modification: string;
    a_Condition: string;
    a_Closed: string;
    a_Changed: boolean;
    UnitAddress: string;
    ResidentName: string;
    employeeFirstName: string;
    employeeLastName: string;
    fileUrl: string | null;
    fileBase64: string | null;
    isFile: boolean;
    receivedStr: string;
    closedStr: string;
    architecturalNotes: ArchitecturalNote[];
};

const styles = {
    architecturalCard: {
        flex: '1 1 300px',
        textAlign: 'start' as const,
        alignSelf: 'stretch',
    }
}

const Architectural = (props: { architecturalData: ArchitecturalData[], }): ReactElement => {
    const { architecturalData } = props;
    const uniqueStatusesArray = Array.from(new Set(["All", ...architecturalData.map(item => item.a_Status).filter(el => el?.trim().length > 0)]));
    const sortedStatuses = uniqueStatusesArray.sort((a, b) => a.localeCompare(b));
    const [selectFilter, setSelectFileter] = useState<string>("All");
    const [filteredNotes, setFilteredNotes] = useState<ArchitecturalData[]>(architecturalData);
    const { applicationStore, modalViewerStore } = useStore();

    useEffect(() => {
        if (selectFilter === "All") {
            setFilteredNotes(architecturalData);
        } else {
            setFilteredNotes(architecturalData.filter(item => item.a_Status === selectFilter))
        }
    }, [architecturalData, selectFilter])

    return (
        <List
            dataSource={filteredNotes}
            header={<Select
                defaultValue="All"
                style={{ width: 300, marginBottom: 10 }}
                onChange={(value: string) => { setSelectFileter(value) }}
                options={sortedStatuses.map(el => { return { value: el, label: el } })}
            />}
            renderItem={(item, index) => (
                <div style={{ borderBottom: "1px solid lightgray", paddingBottom: 10, paddingTop: 10 }}>
                    <Flex wrap="wrap" gap="small">
                        <div style={styles.architecturalCard}>
                            <InlineField label="Received:" value={item.receivedStr} />
                            <InlineField label="Modification:" value={item.a_Modification} />
                            <InlineField label="Status:" value={item.a_Status} />
                        </div>
                        <div style={styles.architecturalCard}>
                            <InlineField label="Employee:" value={item.employeeFirstName} />
                        </div>
                        <div style={styles.architecturalCard}>
                            <InlineField label="Closed:" value={item.a_Closed && item.a_Closed?.split("T")[0] !== "0001-01-01" ? item.closedStr : ""} />
                        </div>
                        <div style={styles.architecturalCard}>
                            <InlineField label="Closed by:" value={
                                item.a_Closed && item.a_Closed?.split("T")[0] !== "0001-01-01"
                                    ? item.employeeLastName + ", " + item.employeeFirstName
                                    : ""
                            } />
                        </div>
                        <Flex vertical flex={"1 1 100%"} gap="small">
                            <InfinityLineField label="Condition:" value={item.a_Condition != null || item.a_Condition !== undefined ? item.a_Condition : ""} />
                            <b>Notes:</b>
                            {item.architecturalNotes && item.architecturalNotes.length > 0
                                ? <Table
                                    dataSource={item.architecturalNotes.map((el, index) => { return { ...el, key: index } })}
                                    pagination={{ defaultPageSize: 10, showSizeChanger: false, responsive: true }}
                                    scroll={{ x: true }}>
                                    <Column width="18%" title="Created Date" dataIndex="created" key="actionDate" ellipsis={true} render={(date) => dateValidation(date)} />
                                    <Column title="Note" dataIndex="note" key="note" ellipsis={true} />
                                    <Column width="10%" key="action" ellipsis={true}
                                        render={(_: any, record: any) => record.fileUrl ? <Button icon={<SearchOutlined />} onClick={() => {
                                            const parts = record.fileUrl.split("/");
                                            const fileName = parts[parts.length - 1];
                                            modalViewerStore.openFileByUrl(record.fileUrl, fileName)
                                        }}>View</Button> : <></>} />
                                </Table>
                                : null}
                        </Flex>
                    </Flex>

                </div>
            )}
        />

    )
}

export default Architectural;