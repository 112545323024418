import React from 'react';
import { Drawer } from 'antd';
import PropTypes from 'prop-types'; // Import PropTypes

const SideBar = (props) => {
  const { close, closable, width, footer, destroyOnClose, mask, title, placement, open, loading, onClose, children, className } = props;

  return (
    <Drawer
      closable={closable}
      destroyOnClose={destroyOnClose}
      title={title}
      placement={placement}
      open={open}
      loading={loading}
      onClose={() => onClose()}
      width={width}
      mask={mask}
      footer={footer}
      maskClosable={close}
      className={className}
    >
      {children()}
    </Drawer>
  );
};

// PropTypes validation
SideBar.propTypes = {
  close: PropTypes.bool, // 'close' should be a boolean
  closable: PropTypes.bool, // 'closable' should be a boolean
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // 'width' can be a string or number
  footer: PropTypes.node, // 'footer' can be any renderable node
  destroyOnClose: PropTypes.bool, // 'destroyOnClose' should be a boolean
  mask: PropTypes.bool, // 'mask' should be a boolean
  title: PropTypes.string, // 'title' should be a string
  placement: PropTypes.oneOf(['left', 'right', 'top', 'bottom']), // 'placement' should be one of the valid Drawer placements
  open: PropTypes.bool, // 'open' should be a boolean
  loading: PropTypes.bool, // 'loading' should be a boolean
  onClose: PropTypes.func.isRequired, // 'onClose' should be a function and is required
  children: PropTypes.func.isRequired, // 'children' should be a function and is required to render content inside the Drawer
  className: PropTypes.string, // 'className' should be a string
};

// Default props (optional)
SideBar.defaultProps = {
  close: false,
  closable: true,
  width: 300,
  footer: null,
  destroyOnClose: false,
  mask: true,
  title: '',
  placement: 'right',
  open: false,
  loading: false,
  className: '',
};

export default SideBar;
