import Constant from '../../Constants/Constants';
  
  export const loginRequest = (credentials) => ({
    type: Constant.LOGIN_REQUEST,
    payload: credentials,
  });
  

  export const loginSuccess = (userData) => ({
    type: Constant.LOGIN_SUCCESS,
    payload: userData,
  });
  
  export const loginFailure = (error) => ({
    type: Constant.LOGIN_FAILURE,
    payload: error,
  });
  
  export const logout = () => ({
    type: Constant.LOGOUT,
  });
  export const Sidebar = () => ({
    type: Constant.IS_SIDEBAR,
  });
  export const setUserRole = (role) => ({
    type: Constant.SET_USER_ROLE,
    payload: role,
  });
  
  class Auth{
    static getDetailsRequest = (data) => ({
        type: Constant.GET_DETAILS_REQUEST,
        payload:data
      });
      
      static getDetailsSuccess = (data) => ({
        type: Constant.GET_DETAILS_SUCCESS,
        payload: data,
      });
      
      static getDetailsFailure = (error) => ({
        type: Constant.GET_DETAILS_FAILURE,
        payload: error,
      });
  }
  export default Auth;