/* eslint-disable */
import { Table } from "antd";
import Column from "antd/es/table/Column";
import * as React from "react";
import { dateValidation } from "../helpers/dateValidation";

export interface AccountDetailProps {
    dataSource: AccountDetailItem[];
}

export type AccountDetailItem = {
    accountNumber: string,
    transactionDate: Date,
    transactionDateStr: string,
    postedDate: Date,
    postedDateStr: string,
    code: string,
    amoun: number,
    balance: number,
    checkNumber: string,
    memo: string
}

const AccountDetail: React.FC<AccountDetailProps> = ({ dataSource }) => {
    const dataSourceWithKey = dataSource.map((el, index) => { return { ...el, key: index } });
    return (
        <div style={{ padding: "10px" }}>
            <Table dataSource={dataSourceWithKey} scroll={{x:true}} pagination={{ defaultPageSize: 10, showSizeChanger: false, responsive:true }}>
                <Column title="Transaction" dataIndex="transactionDateStr" key="transactionDateStr" ellipsis={true} render={(date) => dateValidation(date)}/>
                <Column title="Posted" dataIndex="postedDateStr" key="postedDateStr" ellipsis={true} render={(date) => dateValidation(date)}/>
                <Column title="Code" dataIndex="code" key="code" ellipsis={true}/>
                <Column title="Amount" dataIndex="amount" key="amount" ellipsis={true} render={(date)=> `$${Number(date).toFixed(2)}`}/>
                <Column title="Balance" dataIndex="balance" key="balance" ellipsis={true} render={(date)=> `$${Number(date).toFixed(2)}`}/>
                <Column title="Check #" dataIndex="checkNumber" key="checkNumber" ellipsis={true}/>
                <Column title="Memo" dataIndex="memo" key="memo" ellipsis={true}/>
            </Table>
        </div>
    );
};

export default AccountDetail