import React from 'react';
import PropTypes from 'prop-types';
import { Radio } from 'antd';

const RadioButton = (props) => {
  const { options, name, label, className, onChange, value } = props;

  const handleChange = (e) => {
    const { value } = e.target;
    const selectedOption = options.find(option => option.value === value);
    const selectedName = selectedOption ? selectedOption.name : '';
    const obj = {
      target: {
        id: name,
        value: value,
      },
    };
    onChange(obj, selectedName);
  };

  return (
    <div className="radio-box-wrap">
      {label && <label className={className}>{label}</label>}
      <Radio.Group
        name={name}
        onChange={handleChange}
        value={value}
      >
        {options && Array.isArray(options) && options.length > 0 &&
          options.map((data, index) => (
            <Radio disabled={data?.default} key={index} className={className} value={data.value}>
              {data.name}
            </Radio>
          ))
        }
      </Radio.Group>
    </div>
  );
};

RadioButton.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
      default: PropTypes.bool,
    })
  ).isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

RadioButton.defaultProps = {
  label: '',
  className: '',
  value: null,
};

export default RadioButton;
