// src/pathToLabelMap.js
const pathToLabelMap = {
    '/AAM': 'AAM',
    '/AAM/dashboard': 'Dashboard',
    '/AAM/user': 'Users',
    '/AAM/user/CreateUser': 'Create User',
    '/AAM/user/userDetails/:id': 'View User',
    '/AAM/community/permissions':'Commmunity Permisison',
    '/AAM/community/permissions/:id':'Commmunity Permisison',
    '/AAM/ric':'RIC-Resident Information Console',
  };
  
  export default pathToLabelMap;
  