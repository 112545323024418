/* eslint-disable */
import { Modal } from "antd";
import { ReactElement } from "react";
import { ModalIframeView } from "./ModalIframeView";
import { useStore } from "../../stores/root-store-context";


export const getMimeImageType = (filename: string) => {
    const parts: string[] = filename.split('.');
    const extension = parts.pop()?.toLowerCase() || "";

    const mimeTypes: Record<string, string> = {
        'webp': ' image/webp',
        'jpg': 'image/jpeg',
        'jpeg': 'image/jpeg',
        'png': 'image/png',
        'gif': 'image/gif',
        'bmp': 'image/bmp',
        'ico': 'image/x-icon',
        'pdf': 'application/pdf',
    };
    return { mimeType: mimeTypes[extension] || '', isPdf: extension === "pdf" };
}

export const ModalFileViewer = (props: { isOpenModal: boolean, dismissModal: () => void, urlModal: string, isImage: boolean, documentName?: string }): ReactElement => {
    const { isOpenModal, dismissModal, urlModal, documentName, isImage } = props;
    const { modalViewerStore } = useStore();

    if (isOpenModal) {
        return (
            <Modal
                title={documentName || "Document"}
                style={{ top: "5%", height: "90%", padding:0}}
                styles={{
                    body: {
                        height: "100%"
                    },
                    content:{
                        minHeight: 710,
                        height: "100%"
                    }
                }}
                width={"90%"}
                open={isOpenModal}
                footer={null}
                onCancel={() => {
                    modalViewerStore.urlModal = "";
                    dismissModal()
                }}
            >
                <div style={isImage ? { textAlign: "center", maxHeight: "100%", height:"100%", overflowX: "hidden" } : { maxHeight: "100%",height:"100%", overflowX: "hidden", position: "relative" }}>
                    <ModalIframeView urlModal={urlModal} documentName={documentName} isImage={isImage}/>
                </div>
            </Modal>
        )
    } else {
        return <></>
    }

}
