import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';

const Tables = ({ columns, className, communityList, pagination, data, hasSelected, rowSelection }) => {
  return (
    <div>
      <div
        style={{
          marginBottom: 16,
        }}
      >
        <span
          style={{
            marginLeft: 8,
          }}
        >
          {hasSelected ? communityList : ''}
        </span>
      </div>
      <Table
        scroll={{ x: 400 }}
        bordered
        className={className}
        rowClassName={() => 'custom-row-height'}
        pagination={pagination}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={data}
      />
    </div>
  );
};

Tables.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      dataIndex: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
    })
  ).isRequired,
  className: PropTypes.string,
  communityList: PropTypes.node,
  pagination: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool,
  ]),
  data: PropTypes.array.isRequired,
  hasSelected: PropTypes.bool,
  rowSelection: PropTypes.object,
};

Tables.defaultProps = {
  className: '',
  communityList: null,
  pagination: true,
  hasSelected: false,
  rowSelection: null,
};

export default Tables;
