import { combineReducers } from 'redux';
import authReducer , {Auth} from './Reducers';
import {Users,Communitys} from './'

const rootReducer = combineReducers({
  auth: authReducer,
  getUser : Auth.detailsReducer,
  GetAllUser :  Users.GetAllUsersListReducer,
  Communitys:Communitys?.GetAllCommunitysListReducer,
});

export default rootReducer;
