/* eslint-disable */
import { ReactElement, useState } from "react";
import { Button, Flex, Spin, Table } from 'antd';
import { FileAddOutlined, FilePdfOutlined } from "@ant-design/icons";
import { Column } from "rc-table";
import * as React from "react";
import { dateValidation } from "../helpers/dateValidation";

export type MailItem = {
    dataRecordId: number
    documentType: string | null,
    dateProcessed: string | null,
    dateSubmitted: string | null,
    url: string | null,
}

const ButtonDocumentUploader = (props: { item: MailItem, buttonCallback: (dataRecordId: number) => Promise<string> }): ReactElement => {
    const [status, setStatus] = useState("");
    const [fileUrl, setFileUrl] = useState("");
    if (status === "done") {
        return (
            <Button type="link" icon={<FilePdfOutlined style={{ fontSize: "20px", height: 24, color:"#000000" }} />} href={fileUrl} target="_blank"></Button>
        )
    } else if (status === "pending") {
        return (<Spin size="small" style={{ fontSize: "24px" }}></Spin>)
    } else {
        return (
            <Button type="text" icon={<FileAddOutlined style={{ fontSize: "20px", height: 24 }} />} onClick={async () => {
                setStatus("pending");
                const url = await props.buttonCallback(props.item.dataRecordId);
                setFileUrl(url);
                setStatus("done");
            }}>
            </Button>
        )
    }
}
const MailArhive = (props: { mailItems: MailItem[], openFileCallback: (dataRecordId: number) => Promise<string> }): ReactElement => {
    const { mailItems, openFileCallback } = props;
    return (
        <div style={{ padding: "10px" }}>
            <Table key="MailArhive" dataSource={mailItems} rowKey="dataRecordId" scroll={{ x: true }} pagination={{ defaultPageSize: 10, showSizeChanger: false, responsive:true }}>
                <Column title="Document Type" dataIndex="documentType" key="documentType" ellipsis={true}/>
                <Column title="Date Processed" dataIndex="dateProcessed" key="dateProcessed" ellipsis={true} render={(date) => dateValidation(date)}/>
                <Column title="Date Submitted" dataIndex="dateSubmitted" key="dateSubmitted" ellipsis={true} render={(date) => dateValidation(date)}/>
                <Column title="" dataIndex="url" key="url" render={(value: any, record: MailItem, index: number) => {
                    return (
                        <Flex align="center" justify="center">
                            <ButtonDocumentUploader key={record.dataRecordId + index} item={record} buttonCallback={openFileCallback} />
                        </Flex>
                    )
                }} />
            </Table>
        </div>
    )
}
export default MailArhive;