/* eslint-disable */
import { Spin } from "antd";
import { ReactElement, useState } from "react";


export const ModalIframeView = (props: { urlModal: string, documentName?: string, isImage: boolean }): ReactElement => {
    const { urlModal, documentName } = props;
    const [isLoading, setIsLoading] = useState<boolean>(true);


    return (
        <Spin spinning={isLoading} style={{height:"100%", display:"flex"}} >
            <iframe
                id="ModalViewDownload"
                src={urlModal}
                width="100%"
                height="100%"
                style={{
                    display: "inline-block", maxWidth: "100%", textAlign: "center", justifyContent: "center", alignItems: "center", position: "relative",
                    minHeight: "650px", height:"80vh", minWidth: 200, overflowX:"hidden"
                }}
                title={documentName || "Document"}
                onLoad={() => {
                    setIsLoading(false)
                    if (props.isImage) {
                        const iframe = document.querySelector('#ModalViewDownload');
                        if (iframe instanceof HTMLIFrameElement) {
                            const iframeDoc = iframe.contentDocument || iframe.contentWindow?.document;
                            if (iframeDoc) {
                                const body = iframeDoc.querySelector('body');
                                if (body) {
                                    body.style.margin = "0px";
                                    body.style.height = "100%";
                                    body.style.display = "flex";
                                    body.style.alignItems = "baseline";
                                    body.style.justifyContent = "center";
                                    body.style.overflowX = "hidden";
                                }
                            }
                        }
                    }
                }}
            />
        </Spin>
    )
}
