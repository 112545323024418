export const getPhoneFormat = (phone: string): string => {
    let formattedValue = '';
    if (phone) {
        const cleanedValue = phone.replace(/\D/g, '');
        if (cleanedValue.length <= 3) {
            formattedValue = cleanedValue;
        } else if (cleanedValue.length <= 6) {
            formattedValue = `${cleanedValue.slice(0, 3)}-${cleanedValue.slice(3)}`;
        } else {
            formattedValue = `${cleanedValue.slice(0, 3)}-${cleanedValue.slice(3, 6)}-${cleanedValue.slice(6, 10)}`;
        }
    }
    return formattedValue;
}