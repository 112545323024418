var graph = require('@microsoft/microsoft-graph-client');
class Service{
   

static getAuthenticatedClient = (accessToken) => {
    // Initialize Graph client
    const client = graph.Client.init({
        // Use the provided access token to authenticate
        // requests
        authProvider: (done) => {
            done(null, accessToken);
        }
    });

    return client;
}

static   getUserDetails = async (accessToken)=> {
    const client = this.getAuthenticatedClient(accessToken);

    const user = await client.api('/me').get();
    return user;
}

static  getUsersGroups = async (accessToken)=> {
    const client = this.getAuthenticatedClient(accessToken);

    const user = await client.api('/me/memberOf').get();
    return user;
}
static  getUsers = async (accessToken)=> {
    const client = this.getAuthenticatedClient(accessToken);

    const user = await client.api('/users').get();
    return user;
}
}
export default Service;