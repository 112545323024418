/* eslint-disable */
import { observer } from "mobx-react-lite";
import { Button, Flex, Popconfirm, Space, Spin } from "antd";
import { ReactElement } from "react";
import AccountInfoCard from "./AccountInfoCard";
import * as React from "react";
import { useStore } from "../../../../stores/root-store-context";
import AddContactInfoModal from "../AddContactInfoModal";
import { TypeAddModal } from "../../../../stores/RicStore";
import EStatementModal from "../eStatementModal";

const AccountInfoBlock = observer((): ReactElement => {
  const { ricStore, applicationStore } = useStore();

  return (
    <>
      {ricStore.accountInformation?.accountNumber && (
        <Flex
          align="start"
          wrap="wrap"
          style={{ display: "flex", flexDirection: "row", margin: "15px 0" }}
        >
          <div style={{ flex: "1 1 35%" }}>
            <AccountInfoCard
              key={"accountInfo"}
              accountInfo={ricStore.accountInformation}
            />
          </div>
          <Space
            style={{
              flex: "1",
              flexDirection: "row",
              alignItems: "stretch",
            }}
          >
            {ricStore.accountInformation?.rentersAccNumbers &&
            (ricStore.accountInformation.rentersAccNumbers.length > 0 ||
              ricStore.accountInformation.futuresAccNumbers.length > 0) ? (
              <section className="right-menu-button-link">
                {ricStore.accountInformation.isMainAcc !== true ? (
                  <Button onClick={ricStore.toMainAcc}>
                    Return to Main Account
                  </Button>
                ) : null}
              </section>
            ) : null}
            <Space
              style={{
                flex: "1",
                flexDirection: "column",
                alignItems: "stretch",
              }}
            >
              {ricStore.accountInformation?.rentersAccNumbers &&
              ricStore.accountInformation.rentersAccNumbers.length > 0 &&
              ricStore.accountInformation.isMainAcc === true ? (
                <Button onClick={ricStore.accesAccountRenter}>
                  Access Linked Renter Account
                </Button>
              ) : null}
              {ricStore.accountInformation?.futuresAccNumbers &&
              ricStore.accountInformation.futuresAccNumbers.length > 0 &&
              ricStore.accountInformation.isMainAcc === true ? (
                <Button onClick={ricStore.accesAccountFuture}>
                  Access Linked Future Account
                </Button>
              ) : null}
            </Space>
            <Space
              style={{
                flex: "1",
                flexDirection: "column",
                alignItems: "stretch",
              }}
            >
              <EStatementModal />
              <Button
                block
                onClick={async () => {
                  const url = await ricStore.getUlrLotDocument(
                    ricStore.accountInformation?.accountNumber
                  );
                  const newWindow = window.open(
                    url,
                    "_blank",
                    "noopener,noreferrer"
                  );
                  if (newWindow) newWindow.opener = null;
                }}
              >
                Lot Documents
              </Button>
              <Button
                block
                onClick={() => {
                  window.open(
                    "https://azssrs01.associatedasset.com/ReportServer/Pages/ReportViewer.aspx?%2fDrilldown+Reports%2fVMS+Property+FAQs+(CCR)&rs:Command=Render&vc_property=" +
                      ricStore.accountInformation?.companyKey
                  );
                }}
              >
                Property FAQs
              </Button>
              <Spin spinning={ricStore.isLoadAccountInfo}>
                <Button
                  block
                  onClick={() => {
                    ricStore.createNewPdfFinancials();
                  }}
                >
                  Print Account Detail
                </Button>
              </Spin>
              <Spin spinning={ricStore.isLoadLoaderZEGO}>
                <Button
                  block
                  onClick={() => {
                    ricStore.createPdf();
                  }}
                >
                  ZEGO Pay Now Info
                </Button>
              </Spin>
            </Space>
            <Space
              style={{
                flex: "1 1 10%",
                flexDirection: "column",
                alignItems: "stretch",
              }}
            >
              {ricStore.accountInformation?.activeRenters?.length == 0 &&
              ricStore.accountInformation.subLedgerType ==
                "Owner" ? null : ricStore.accountInformation.subLedgerType ==
                "Renter" ? (
                <Popconfirm
                  title="DEACTIVATE ACCOUNT"
                  description={
                    <div style={{ width: 250 }}>
                      Are you sure you want to deactivate this account? This is
                      not reversible and <br />
                      the account will lose access to all AAM systems.
                    </div>
                  }
                  okText="Confirm"
                  cancelText="Cancel"
                  onConfirm={() => {
                    ricStore
                      .deactivateAccountRenter()
                      .then((res) => {
                        ricStore.getFilterList(
                          applicationStore.SelectedProperties.id
                        );
                      })
                      .catch(console.log);
                  }}
                >
                  <Button
                    block
                    disabled={
                      !ricStore.isEditable ||
                      !ricStore.accountInformation?.isETrakType ||
                      !(ricStore.filterList?.eTrakAccounts?.length > 0)
                    }
                  >
                    Deactivate Renter Acct
                  </Button>
                </Popconfirm>
              ) : null}

              <AddContactInfoModal
                text={"Add Contact"}
                type={TypeAddModal.AddContact}
              />
              {(!ricStore.accountInformation?.futuresAccNumbers ||
                ricStore.accountInformation.futuresAccNumbers.length == 0) &&
              ricStore.accountInformation.isMainAcc === true ? (
                <AddContactInfoModal
                  text={"Add Future Account"}
                  type={TypeAddModal.AddFeatureContact}
                />
              ) : (
                <></>
              )}
              {(!ricStore.accountInformation?.rentersAccNumbers ||
                ricStore.accountInformation.rentersAccNumbers.length == 0) &&
              ricStore.accountInformation.isMainAcc === true ? (
                <AddContactInfoModal
                  text={"Add Renter Account"}
                  type={TypeAddModal.AddRentedContact}
                />
              ) : (
                <></>
              )}
            </Space>
          </Space>
        </Flex>
      )}
    </>
  );
});

export default AccountInfoBlock;
