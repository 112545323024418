import React from 'react';
import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';
import Header from '../layout-components/Header';
import SideBar from '../layout-components/Sidebar';
import { Footer } from 'antd/es/layout/layout';
import MainLayouts from '../BreadCrumb/MainLayOut'
const { Content } = Layout;

const MainLayout = () => (
  <Layout style={{ minHeight: '100vh' }}>
       <SideBar />
    <Layout>
      <Header />
      <div style={{ padding: 24}}>
      <MainLayouts /></div>
      <Content style={{ margin: '0px 0px 0px' }}>
        <div style={{ padding: 24}}>
          <Outlet />
        </div>
      </Content>
  
      <Footer  style={{background:"#fff",padding:'15px'}} ><p  style={{margin:'0',padding:'0'}}>Footer</p></Footer>
 
    </Layout>
  </Layout>
);

export default MainLayout;
