/* eslint-disable */
import { AxiosResponse } from "axios";
import SpaToolsApiClient from "./base/SpaToolsApiClient";
import { ContactInformation } from "../components/RIC/components/ContactInfo";
import { AddContactInfoResponse } from "../stores/RicStore";
import { ISearchItem } from "../components/RIC/components/AdvancedSearch";
import { mergeWithDefault } from "../components/RIC/helpers/searchHelper";

export type UnitInfoForAddContact = {
  city: string;
  country: string;
  door: string;
  postalCode: string;
  region: string;
  streetName: string;
  streetNumber: string;
  unitKey: string;
};

export type EStatementSignData = {
  AccountNumber: string;
  Email: string;
  SendMail: boolean;
  Username: string;
};

export type EStatementModalData = {
  accountNumber: string | null;
  email: string;
  sendMail: boolean;
  sendMailStr: string;
  username: string | null;
};

export default class RicApiClient extends SpaToolsApiClient {
  // created methods
  public getSearchFilters = async (id: number): Promise<AxiosResponse> => {
    return await this.Get(`VMS/getFilterList?propertyId=${id}`);
  };

  public getContactInformation = async (
    account: string | number
  ): Promise<AxiosResponse> => {
    return await this.Get(`ContactInfo/contact-info?accountNumber=${account}`);
  };

  public getAccountInformation = async (
    account: string | number
  ): Promise<AxiosResponse> => {
    return await this.Get(
      `AccountInformation/getAccountInformation?accountNumber=${account}`
    );
  };

  public getComplianceInformation = async (
    account: string | number
  ): Promise<AxiosResponse> => {
    return await this.Get(`Compliance/get?accountNumber=${account}`);
  };

  public getNotesInformation = async (
    account: string | number
  ): Promise<AxiosResponse> => {
    return await this.Get(
      `notes?accountNumber=${account}&userLogin=${this._configApi.userEmail}`
    );
  };

  public getNordisInformation = async (
    account: string | number
  ): Promise<AxiosResponse> => {
    return await this.Get(`nordisdirect/get?accountID=${account}`);
  };

  public getArchitecturalInformation = async (
    account: string | number
  ): Promise<AxiosResponse> => {
    return await this.Get(
      `Architectural/architectural?accountNumber=${account}`
    );
  };
  public getAccountDetail = async (
    account: string | number
  ): Promise<AxiosResponse> => {
    return await this.Get(`Financial/financial/${account}`);
  };
  public getPropertyList = async (): Promise<AxiosResponse> => {
    return await this.Get(`VMS/GetPropertyList`);
  };
  public getSearchAccounts = async (text: string): Promise<AxiosResponse> => {
    return await this.Get(`/VMS/search?text=${text}`);
  };
  public getMailArchive = async (
    account: string | number
  ): Promise<AxiosResponse> => {
    return await this.Get(`nordisdirect/get?accountID=${account}`);
  };
  public getCompliance = async (
    account: string | number
  ): Promise<AxiosResponse> => {
    return await this.Get(`compliance/get?accountNumber=${account}`);
  };
  public getPdfByDataRecordId = async (
    dataRecordId: number
  ): Promise<AxiosResponse> => {
    return await this.Get(
      `nordisdirect/generetePdfUrl?dataRecordId=${dataRecordId}`
    );
  };
  public getLotDocumentGetProperies = async (
    dataRecordId: string
  ): Promise<AxiosResponse> => {
    return await this.Get(
      `/VMS/LotDocumentgetProperies?accountNumber=${dataRecordId}`
    );
  };
  public saveContactInfo = async (
    id: string,
    contactInfo: FormData
  ): Promise<AxiosResponse> => {
    return await this.Post(`/ContactInfo/contact-info/${id}`, contactInfo);
  };
  public deleteContactInfo = async (
    methodId: string,
    str: string,
    accountNumber: string,
    currentEmployeeKey: string
  ): Promise<AxiosResponse> => {
    return await this.Delete(
      `/ContactInfo/contact-info/${methodId}&${str}&${accountNumber}&${currentEmployeeKey}`
    );
  };
  public deleteContactItem = async (data: any): Promise<AxiosResponse> => {
    return await this.Delete(`/ContactInfo/delete-contact`, data);
  };
  public saveNote = async (noteInfo: FormData): Promise<AxiosResponse> => {
    return await this.Post(`/notes/post`, noteInfo);
  };
  public deactivateRenter = async (
    accountNumber: string
  ): Promise<AxiosResponse> => {
    return await this.Post(
      `/ContactInfo/DeactivateRenter?residentKey=${accountNumber}`
    );
  };

  public getUnitInfoForAddContact = async (
    accountId: string
  ): Promise<AxiosResponse> => {
    return await this.Get(
      `AccountInformation/getUnitInfoForAddContact?accountNumber=${accountId}`
    );
  };

  public saveContact = async (
    contactData: AddContactInfoResponse
  ): Promise<AxiosResponse> => {
    return await this.Post(`ContactInfo/contact-add`, contactData);
  };
  public saveRenterAccount = async (
    contactData: AddContactInfoResponse
  ): Promise<AxiosResponse> => {
    return await this.Post(`ContactInfo/AddRenterAccount`, contactData);
  };
  public saveFutureOwnerAccount = async (
    contactData: AddContactInfoResponse
  ): Promise<AxiosResponse> => {
    return await this.Post(`ContactInfo/AddFutureOwnerAccount`, contactData);
  };
  public searchAccountInformation = async (
    itemSearch: ISearchItem
  ): Promise<AxiosResponse> => {
    return await this.Post(
      `AccountInformation/AdvancedSearch/search`,
      mergeWithDefault(itemSearch)
    );
  };
  public createPdfWithContacInfo = async (data: {
    FirstName: any;
    LastName: any;
    AccountNumber: any;
    Email: any;
  }): Promise<AxiosResponse> => {
    return await this.Post(`ContactInfo/CreatePdfWithContacInfo`, data);
  };

  public сreateNewPdfFinancials = async (
    accountNumber: string
  ): Promise<AxiosResponse> => {
    return await this.Get(
      `Financial/CreateNewPdfFinancials?accountNumber=${accountNumber}`
    );
  };

  public getEStatementSign = async (
    account: string
  ): Promise<AxiosResponse> => {
    return await this.Get(`/VMS/e-statement-sign-up?accountNumber=${account}`);
  };

  public postEStatementSign = async (
    data: EStatementSignData
  ): Promise<AxiosResponse> => {
    return await this.Post(`/VMS/e-statement-sign-up-post`, data);
  };
}
