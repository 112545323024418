import moment from "moment";
class CommonFunctions {
  static disableFutureDates = (current) => {

    return current && current > moment().endOf("day");
  };

  static formatDate(inputDate) {
    if (inputDate) {
      return moment(inputDate).format("MM/DD/YYYY");
    }
    return "-";
  }
  static getDigits = (str, number) => {
    return str.slice(number);
  };
  static hasRole=(user, roleName) =>{
    return user?.roles?.some((role) => role?.roleName === roleName);
  }
static formatPhoneNumber = (input) => {
    if (!input) return "";
    const cleaned = ("" + input).replace(/\D/g, "").slice(0, 10);
    // Format the cleaned input
    const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
    if (match) {
      return [match[1], match[2], match[3]].filter(Boolean).join("-");
    }
    return input;
  };
}

export default CommonFunctions;