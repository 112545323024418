/* eslint-disable */
import * as React from "react";
import { FocusZone, FocusZoneDirection } from "@fluentui/react/lib/FocusZone";
import { ITheme, mergeStyleSets, getTheme } from "@fluentui/react/lib/Styling";
import { ISearchItem } from "./AdvancedSearch";
import { Button, Col, List, Row, Spin } from "antd";


const theme: ITheme = getTheme();
const { palette, semanticColors, fonts } = theme;

const classNames = mergeStyleSets({
  itemCell: [
    {
      textAlign: "start",
      display: "flex",
      minHeight: 54,
      padding: 10,
      borderBottom: `1px solid ${semanticColors.bodyDivider}`,
      selectors: {
        "&:hover": { background: palette.neutralLight },
      },
    },
  ],
  itemContent: {
    marginLeft: 10,
    // overflow: 'hidden',
    flexGrow: 1,
  },
  itemName: [
    fonts.medium,
    {
      padding: "10px 0",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  ],
  itemIndex: {
    fontSize: fonts.small.fontSize,
    color: palette.neutralTertiary,
    marginBottom: 10,
  },
  overflowText: [
    {
      WebkitLineClamp: 3,
    },
    fonts.medium,
    {
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      padding: 0,
    },
  ],
  header: {
    color: palette.neutralTertiary,
    textAlign: "center",
  },
  chevron: {
    alignSelf: "center",
    marginLeft: 10,
    color: palette.neutralTertiary,
    fontSize: fonts.large.fontSize,
    flexShrink: 0,
    verticalAlign: "text-top",
  },
});

export interface IListAdvancedSearch {
  items: ISearchItem[];
  isLoading: boolean;
  callBackForItem: (item: ISearchItem) => void;
}

const onRenderCell = (
  item: ISearchItem,
  callBackForItem: (item: ISearchItem) => void
): JSX.Element => {
  return (
    <>
      <Row className={classNames.itemCell} data-is-focusable={true}>
        <Col span={7}>
          <div className={classNames.overflowText}>
            <strong>Community: </strong>
            {item.community}
          </div>
          <div className={classNames.overflowText}>
            <strong>Account Number: </strong>
            {item.account}
          </div>
        </Col>
        <Col span={7}>
          <div className={classNames.overflowText}>
            <strong>Name: </strong>
            {item.name}
          </div>
          <div className={classNames.itemName}>
            <strong>Email: </strong>
            {item.email == "" || item.email == undefined || item.email == null
              ? "n/a"
              : item.email}
          </div>
        </Col>
        <Col span={7}>
          <div className={classNames.overflowText}>
            <strong>Address: </strong>
            {item.address == "" ||
              item.address == undefined ||
              item.address == null
              ? "n/a"
              : item.address}
          </div>
          <div className={classNames.itemName}>
            <strong>Phone: </strong>
            {item.phone == "" || item.phone == undefined || item.phone == null
              ? "n/a"
              : item.phone}
          </div>
        </Col>
        <Col span={3}>
          <Button type="link"
            onClick={() => {
              callBackForItem(item);
            }}>View</Button>
        </Col>
      </Row>

      {/* <div className={classNames.itemCell} data-is-focusable={true}>
        <div className="col-12 row text-start" style={{ alignItems: "center" }}>
          <div className="col-sm-3 col-12 gx-2">
            <div className={classNames.overflowText}>
              <strong>Community: </strong>
              {item.community}
            </div>
            <div className={classNames.overflowText}>
              <strong>Account Number: </strong>
              {item.account}
            </div>
          </div>
          <div className="col-sm-4 col-12 gx-2">
            <div className={classNames.overflowText}>
              <strong>Name: </strong>
              {item.name}
            </div>
            <div className={classNames.itemName}>
              <strong>Email: </strong>
              {item.email == "" || item.email == undefined || item.email == null
                ? "n/a"
                : item.email}
            </div>
          </div>
          <div className="col-sm-4 col-12 gx-2">
            <div className={classNames.overflowText}>
              <strong>Address: </strong>
              {item.address == "" ||
                item.address == undefined ||
                item.address == null
                ? "n/a"
                : item.address}
            </div>
            <div className={classNames.itemName}>
              <strong>Phone: </strong>
              {item.phone == "" || item.phone == undefined || item.phone == null
                ? "n/a"
                : item.phone}
            </div>
          </div>
          <div className="col-sm-1 col-12 gx-2 text-center">
            <button
              className="btn text-decoration-underline"
              onClick={() => {
                callBackForItem(item);
              }}
            >
              view
            </button>
          </div>
        </div>
      </div> */}
    </>
  );
};

export function ListAdvancedSearch(
  props: IListAdvancedSearch
): React.ReactElement {
  const [items] = React.useState(props.items);
  const [isLoading] = React.useState(props.isLoading);

  return (
    <FocusZone
      direction={FocusZoneDirection.vertical}
      style={{ minHeight: 300 }}
    >
      {items.length > 0 || isLoading ? (
        <List style={{ overflowY: "auto", maxHeight: 300 }}>
          {items.map((item) => onRenderCell(item, props.callBackForItem))}
        </List>
      ) : (
        <h2 className={classNames.header}>Items not-found</h2>
      )}
    </FocusZone>
  );
}

export default ListAdvancedSearch;
