/* eslint-disable */
import { AutoComplete, Select, Spin } from "antd";
import { observer } from "mobx-react-lite";

import React, { useState } from "react";
import { useStore } from "../../../../stores/root-store-context";

const stylesRic = {
    container: {
        display: "flex",
        flexFlow: "row wrap"
    },
    formField: {
        height: "38px",
        maxHeight: "38px",
        fontSize: "1rem",
        margin: "4px",
    },
}

const AllSearch = observer(() => {
    const { applicationStore, ricStore } = useStore();
    const [searchText, setSearchText] = useState<string>("");

    const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter') {
            ricStore.getAllSearchList(searchText);
        }
    };

    return (
        <Spin spinning={ricStore.isLoadingAllSearch || ricStore.isLoadingApp}>
            <div style={stylesRic.container}>
                <AutoComplete
                    style={{ ...{ minWidth: 150, maxWidth: 400, flex: "1 1 400px" }, ...stylesRic.formField }}
                    options={ricStore.searchAllAccounts.map(option => ({ value: option.strSearchResult, residentKey: option.residentKey }))}
                    placeholder={<b>Please enter the text and press Enter</b>}
                    searchValue={searchText}
                    value={searchText}
                    onChange={setSearchText}
                    onKeyDown={handleKeyPress}
                    onSelect={(el, option) => {
                        ricStore.setAccount(option.residentKey);
                        setSearchText(el);
                    }}
                />
            </div>
        </Spin>

    );
});

export default AllSearch;
