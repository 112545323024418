/* eslint-disable */
import React, { useState, useEffect } from "react";
import { App, ConfigProvider } from "antd"; 
import RIC from "./webparts/ric/components/Ric"; 
//import { ConfigApi } from "./webparts/ric/api/base/ConfigApi";
import { RootStoreContext, useStore } from "./webparts/ric/stores/root-store-context";
import RootStore from "./webparts/ric/stores/root-store";
import UserData from "../../../utils/UserData";
import PrefixPath from "../../../config/AppConfig";


const AppComponent = () => {
  const [userEmail, setUserEmail] = useState("");
  const user = UserData();
  const fetchUserEmail = async () => {
    return user?.getUserInformation()?.displayName;
  };

  const [isEditable] = useState(true); 
  const [domain, setDomain] = useState(PrefixPath.RIC_API_BASE_URL); 
  const [xApiKey, setXApiKey] = useState(PrefixPath.API_KEY); 

  // Load user email when component mounts
  useEffect(() => {
    const getUserEmail = async () => {
      const email = await fetchUserEmail();
      setUserEmail(email);
    };
    getUserEmail();
  }, []);

  // Configuration object for the app
  const config = {
    domain,
    userEmail,
    xApiKey,
  };
 
  return (
    <ConfigProvider
      theme={{
        token: {},
        components: {
          Collapse: {
            colorBorder: "none",
            headerBg: "#ffffff",
            paddingSM: 6,
            padding: 0,
          },
          Button: {
            borderRadius: 4,
            colorPrimary: "#5a7890",
            colorPrimaryHover: "rgba(47, 75, 98, 1)",
            colorPrimaryActive: "rgba(47, 75, 98, 1)",
            colorPrimaryTextActive: "#ffffff",
          },
          Typography: {
            fontSize: 16,
          },
        },
      }}
    >
      <RootStoreContext.Provider value={new RootStore(config, isEditable)}>
        <App>
          <RIC />
        </App>
      </RootStoreContext.Provider>
    </ConfigProvider>
  );
};

export default AppComponent;
