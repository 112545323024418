import React from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';
import Login from 'Views/Auth-Views/Login'
import PrivateRoute from './ProtectedRoute';
import PublicRoute from './PublicRoute';
import {ROLES} from 'Constant/Roles'
import {UtilsComponent} from 'Components';
import { Users,CreateUser,ViewUsers,CommunityPermission } from 'Views/App-Views';
import PrefixPath from 'config/AppConfig';
import AppComponent from '../Views/App-Views/RIC/Ric';

const {MainLayout} = UtilsComponent;
const router = createBrowserRouter([{
  path: "/",
  element: <PrivateRoute element={<Navigate to={`${PrefixPath.APP_PREFIX_PATH}/user`} />} requiredRoles={[ROLES.ADMIN, ROLES.BOARD_ADMIN, ROLES.USER]} />, // Redirect from / to /ss
},
  {
    path: `${PrefixPath.APP_PREFIX_PATH}/`,
    element: <MainLayout/>,
    children: [
      {
        path: `${PrefixPath.APP_PREFIX_PATH}/user`,
        element: <PrivateRoute element={<Users/>} requiredRoles={[ROLES.ADMIN, ROLES.BOARD_ADMIN, ROLES.USER]} />,
      },
      {
        path: `${PrefixPath.APP_PREFIX_PATH}/ric`,
        element: <PrivateRoute element={<AppComponent/>} requiredRoles={[ROLES.ADMIN, ROLES.BOARD_ADMIN, ROLES.USER]} />,
      },
      {
        path: `${PrefixPath.APP_PREFIX_PATH}/user/userDetails/:id`,
        element: <PrivateRoute element={<ViewUsers/>} requiredRoles={[ROLES.ADMIN, ROLES.BOARD_ADMIN, ROLES.USER]} />,
      },
      {
        path: `${PrefixPath.APP_PREFIX_PATH}/user/CreateUser`,
        element: <PrivateRoute element={<CreateUser/>} requiredRoles={[ROLES.ADMIN, ROLES.BOARD_ADMIN, ROLES.USER]} />,
      },
      {
        path: `${PrefixPath.APP_PREFIX_PATH}/dashboard`,
        element: <PrivateRoute element={<>dashboard</>} requiredRoles={[ROLES.ADMIN, ROLES.BOARD_ADMIN]} />,
      },
      {
        path: `${PrefixPath.APP_PREFIX_PATH}/community/permissions`,
        element: <PrivateRoute element={<CommunityPermission/>} requiredRoles={[ROLES.ADMIN, ROLES.BOARD_ADMIN, ROLES.USER]} />,
      },
      {
        path: `${PrefixPath.APP_PREFIX_PATH}/community/permissions/:id`,
        element: <PrivateRoute element={<CommunityPermission/>} requiredRoles={[ROLES.ADMIN, ROLES.BOARD_ADMIN, ROLES.USER]} />,
      },
      {
        path: `${PrefixPath.APP_PREFIX_PATH}/unauthorized`,
        element: <>unauthorized</>,
      },
    ],
  },
  {
    path: `/login`,
    element:'',
    children: [
      {
        path: `/login`,
        element: <PublicRoute element={<Login />} />,
      },
    ],
  },
]);



export default router;
