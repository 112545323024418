/* eslint-disable */
import { ConfigApi } from "../api/base/ConfigApi";
import ApiStore from "./ApiStore";
import ApplicationStore from "./ApplicationStore";
import ModalViewerStore from "./ModalViewerStore";
import RicStore from "./RicStore";

class RootStore {
  applicationStore: ApplicationStore;
  ricStore: RicStore;
  modalViewerStore: ModalViewerStore;
  constructor(configApi: ConfigApi, isEditable: boolean) {
    const api = new ApiStore(configApi);

    this.applicationStore = new ApplicationStore(api);
    this.ricStore = new RicStore(api, isEditable);
    this.modalViewerStore = new ModalViewerStore(api);
  }

}

export default RootStore;
