import React from 'react';


const Spinner = () => {
  return(
  <div className="drawing" id="loading">
		<div className="loading-dot"></div>
	</div>
  )
}

export default Spinner;
