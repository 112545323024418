import '../src/assests/styles/styles.scss'
import { RouterProvider } from "react-router-dom";
import AppRoutes from "Routes/routesConfig";
function App() {
  return (
    <div>
       <RouterProvider router={AppRoutes} />
    </div>
  );
}

export default App;
