/* eslint-disable */
import { ISearchItem } from "../components/AdvancedSearch";

const defaultSearchItem: ISearchItem = {
    account: null,
    community: null,
    blockLot: null,
    name: null,
    address: null,
    phone: null,
    email: null,
    priority: false,
};

export function mergeWithDefault(searchItem: ISearchItem): ISearchItem {
    return { ...defaultSearchItem, ...searchItem };
}