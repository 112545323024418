/* eslint-disable */
import { observer } from "mobx-react-lite";
import { ReactElement, useRef, useState } from "react";
import * as React from "react";
import { useStore } from "../../../../stores/root-store-context";
import { UserEmployee } from "../../../../api/base/ConfigApi";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Space,
  Spin,
  Upload,
  UploadProps,
} from "antd";
import styles from "./notes.module.scss";
import dayjs from "dayjs";
import TextArea from "antd/es/input/TextArea";
import {
  PaperClipOutlined,
  CloudUploadOutlined,
  LoadingOutlined,
} from "@ant-design/icons";

export type NoteType = {
  writeAccess: boolean;
  accountNumber: string;
  NoteID: string;
  opened: any;
  openedStr: string;
  openedBy: UserEmployee;
  openEmployeeKey: string;
  closed: any;
  closedStr: string;
  created: any;
  closedBy: UserEmployee;
  assignedTo: UserEmployee;
  closeEmployeeKey: string;
  category1: string;
  category2: string | null;
  noteText: string;
  resolution: string;
  Type: string;
  contact: string;
  fileName: string;
  fileUrl: string;
  onWeb: "Yes" | "No";
  responsibleEmployeeKey: string;
  binaryFile: any | null;
  isFile: boolean;
  isNew?: boolean;
};

const Note = observer(
  (props: { item: NoteType; background: string }): ReactElement => {
    const { ricStore, applicationStore } = useStore();
    const [form] = Form.useForm();
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const [isUpload, setIsUpload] = useState<boolean>(false);
    const [note, setNote] = useState<NoteType>(props.item);
    const fileInput = useRef(null);

    const changeIsUpdating = (status: boolean) => {
      setIsUpdating(status);
    };
    const changeIsUploading = (status: boolean) => {
      setIsUpload(status);
    };
    const getDataStr = (date: dayjs.Dayjs): string => {
      if (date)
        return `${date.year()}-${
          date.month() < 9 ? `0${date.month() + 1}` : date.month() + 1
        }-${date.date()}`;

      return "";
    };

    return (
      <Spin spinning={isUpdating}>
        <Form
          className={styles.noteItem}
          form={form}
          variant="outlined"
          layout="vertical"
          initialValues={note}
          labelCol={{ span: 24 }}
          style={{
            width: 1150,
            background: props.background,
            padding: 8,
            border: note.isNew ? "2px solid red" : "none",
          }}
          onValuesChange={(changedValues, allValues) => setNote(allValues)}
          // validateMessages={validateMessages}
          // requiredMark={customizeRequiredMark}
          autoComplete="off"
          scrollToFirstError={{ block: "center", inline: "nearest" }}
        >
          <Row>
            <Col span={24}>
              <Form.Item<NoteType> name="closeEmployeeKey" hidden>
                <Input />
              </Form.Item>
              <Form.Item<NoteType> name="responsibleEmployeeKey" hidden>
                <Input />
              </Form.Item>
              <Form.Item<NoteType> name="contact" hidden>
                <Input />
              </Form.Item>
              <Form.Item<NoteType> name="created" hidden>
                <Input />
              </Form.Item>
              <Form.Item<NoteType> name="fileName" hidden>
                <Input />
              </Form.Item>
              <Form.Item<NoteType> name="onWeb" hidden>
                <Input />
              </Form.Item>
              <Form.Item<NoteType> name="openEmployeeKey" hidden>
                <Input />
              </Form.Item>
              <Form.Item<NoteType> name="Type" hidden>
                <Input />
              </Form.Item>
              <Form.Item<NoteType> name="accountNumber" hidden>
                <Input />
              </Form.Item>
              <Form.Item<NoteType> name="NoteID" hidden>
                <Input />
              </Form.Item>
              <Form.Item<NoteType> name="resolution" hidden>
                <Input />
              </Form.Item>
              <Form.Item<NoteType> name="binaryFile" hidden>
                <Input type="file" ref={fileInput} value={note.binaryFile} />
              </Form.Item>
            </Col>
          </Row>
          <Row align={"middle"}>
            <Col span={2} style={{ textAlign: "center" }}>
              Opened
            </Col>
            <Col span={4}>
              <Form.Item<NoteType> name="openedStr" style={{ margin: 5 }}>
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col span={2} style={{ textAlign: "center" }}>
              Opened By
            </Col>
            <Col span={4}>
              <Form.Item<NoteType> style={{ margin: 5 }}>
                <Input disabled={true} value={note?.openedBy?.employeeName} />
              </Form.Item>
            </Col>
            <Col span={2} style={{ textAlign: "center" }}>
              Closed
            </Col>
            <Col span={4}>
              <Form.Item<NoteType> name="closed" style={{ margin: 5 }}>
                <DatePicker
                  onChange={(date) => {
                    console.log("onBlur");
                    ricStore
                      .updateNote(form.getFieldsValue(), changeIsUpdating)
                      .catch(console.log);
                  }}
                  format={"MM/DD/YYYY"}
                  disabled={!ricStore.isEditable}
                />
              </Form.Item>
            </Col>
            <Col span={2} style={{ textAlign: "center" }}>
              Closed By
            </Col>
            <Col span={4}>
              <Form.Item<NoteType> style={{ margin: 5 }}>
                <Input disabled={true} value={note?.closedBy?.employeeName} />
              </Form.Item>
            </Col>
          </Row>
          <Row align={"middle"}>
            <Col span={2} style={{ textAlign: "center" }}>
              Type
            </Col>
            <Col span={8}>
              <Form.Item<NoteType> name="Type" style={{ margin: 5 }}>
                <Select
                  options={ricStore.typeItems.map((el, index) => {
                    return { value: el, label: el };
                  })}
                  onBlur={() => {
                    console.log("onBlur");
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={2} style={{ textAlign: "center" }}>
              Category 1
            </Col>
            <Col span={8}>
              <Form.Item<NoteType> name="category1" style={{ margin: 5 }}>
                <Select
                  options={ricStore.category1Items.map((el, index) => {
                    return { value: el, label: el };
                  })}
                  onBlur={() => {
                    console.log("onBlur");
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={2}>
              <b>Note</b>
            </Col>
            <Col span={14}>
              <Form.Item<NoteType> name="noteText" style={{ margin: 5 }}>
                <TextArea
                  rows={3}
                  onBlur={() => {
                    const noteData = form.getFieldsValue();
                    ricStore
                      .updateNote(noteData, changeIsUpdating)
                      .catch(console.log);
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={6} style={{ verticalAlign: "bottom", bottom: 0 }}>
              <Upload
                showUploadList={false}
                onChange={(info) => {
                  if (!ricStore.isUploadFile) {
                    setIsUpload(true);
                    const newNote = {
                      ...note,
                      binaryFile: info.file.originFileObj,
                      fileName: info.file.name,
                    };
                    setNote(newNote);
                    ricStore
                      .updateFileNote(newNote, changeIsUploading)
                      .then((fileUrl) => {
                        setNote((prev) => {
                          return {
                            ...prev,
                            fileUrl: fileUrl,
                          };
                        });
                      })
                      .catch(console.log);
                  }
                }}
                listType="text"
                onPreview={() => {
                  <></>;
                }}
              >
                <Button
                  icon={
                    isUpload ? <LoadingOutlined /> : <CloudUploadOutlined />
                  }
                >
                  Upload
                </Button>
              </Upload>
              {!isUpload && note.fileName.length ? (
                <Button
                  icon={<PaperClipOutlined />}
                  onClick={() => {
                    window.open(note.fileUrl, "_blank");
                  }}
                >
                  View
                </Button>
              ) : (
                <></>
              )}
            </Col>
          </Row>
          <Row>
            <Col span={2}>
              <b>Resolution</b>
            </Col>
            <Col span={14}>
              <Form.Item<NoteType> name="resolution" style={{ margin: 5 }}>
                <Input
                  disabled={!ricStore.isEditable}
                  onBlur={() => {
                    console.log("onBlur");
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    );
  }
);
export default Note;
