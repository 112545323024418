import React from "react";
import { Typography } from "antd";
import PropTypes from "prop-types"; // Import PropTypes for validation

const { Title } = Typography;

const TypographyComponent = (props) => {
  const { children, className, level, type } = props;
  return (
    <Title className={className} level={level} type={type}>
      {children}
    </Title>
  );
};

TypographyComponent.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  level: PropTypes.oneOf([1, 2, 3, 4, 5, 6]), 
  type: PropTypes.oneOf(['secondary', 'danger', 'success', 'warning', 'default']), 
};

TypographyComponent.defaultProps = {
  className: '',
  level: 1, 
  type: 'default', 
};

export default TypographyComponent;
