import React from 'react';
import PropTypes from 'prop-types';
import { Radio, Form } from 'antd';

const RadioButton = (props) => {
  const { options, name, label, rules, className, onChange } = props;

  const handleChane = (e) => {
    const { value, name } = e.target; // Removed optional chaining
    const obj = {
      target: {
        id: name,
        value: value,
      },
    };
    onChange(obj);
  };

  return (
    <Form.Item name={name} label={label} rules={rules}>
      <Radio.Group name={name} onChange={handleChane}>
        {options && Array.isArray(options) && options.length > 0 &&
          options.map((data, index) => (
            <Radio.Button key={index} className={className} value={data.value}>
              {data.name}
            </Radio.Button>
          ))}
      </Radio.Group>
    </Form.Item>
  );
};

RadioButton.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  rules: PropTypes.array,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

RadioButton.defaultProps = {
  label: '',
  rules: [],
  className: '',
};

export default RadioButton;
