/* eslint-disable */
import { observer } from "mobx-react-lite";
import { ReactElement, useRef, useState } from "react";
import * as React from "react";
import { useStore } from "../../../../stores/root-store-context";
import { UserEmployee } from "../../../../api/base/ConfigApi";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  Upload,
  UploadProps,
} from "antd";
import { ContactInformation } from "../ContactInfo";
import { usStates } from "../../helpers/State";

const EditNameAddressForm = observer(
  (props: {
    isOpenModal: boolean;
    dismissModal: () => void;
    contact: ContactInformation;
  }): ReactElement => {
    const { ricStore, applicationStore } = useStore();
    const [form] = Form.useForm();
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const [addContactInfo, setAddContactInfo] = useState<ContactInformation>(
      JSON.parse(JSON.stringify(props.contact))
    );
    const { isOpenModal, dismissModal } = props;

    const getModifiedString = (
      oldData: ContactInformation,
      newData: ContactInformation
    ): string => {
      let newModifiedString = "";
      if (oldData.firstName !== newData.firstName) {
        newModifiedString += `First Name modified from '${
          oldData.firstName
        }' to '${newData.firstName}' for contact id '${
          newData.contactId ? newData.contactId : oldData.contactId
        }'.\n`;
      }
      if (oldData.lastName !== newData.lastName) {
        newModifiedString += `Fast Name modified from '${
          oldData.lastName
        }' to '${newData.lastName}' for contact id '${
          newData.contactId ? newData.contactId : oldData.contactId
        }'.\n`;
      }
      if (
        oldData.contactAddress?.mailingAddress1 !==
        newData.contactAddress?.mailingAddress1
      ) {
        newModifiedString += `Address modified from '${
          oldData.contactAddress?.mailingAddress1
        }' to '${newData.contactAddress?.mailingAddress1}' for contact id '${
          newData.contactId ? newData.contactId : oldData.contactId
        }', ${
          newData.contactFullName
            ? newData.contactFullName
            : oldData.contactFullName
        }.\n`;
      }
      if (
        oldData.contactAddress?.mailingAddress2 !==
        newData.contactAddress?.mailingAddress2
      ) {
        newModifiedString += `Address 2 modified from '${
          oldData.contactAddress?.mailingAddress2
        }' to '${newData.contactAddress?.mailingAddress2}' for contact id '${
          newData.contactId ? newData.contactId : oldData.contactId
        }', ${
          newData.contactFullName
            ? newData.contactFullName
            : oldData.contactFullName
        }.\n`;
      }
      if (
        oldData.contactAddress?.mailingCity !==
        newData.contactAddress?.mailingCity
      ) {
        newModifiedString += `City modified from '${
          oldData.contactAddress?.mailingCity
        }' to '${newData.contactAddress?.mailingCity}' for contact id '${
          newData.contactId ? newData.contactId : oldData.contactId
        }', ${
          newData.contactFullName
            ? newData.contactFullName
            : oldData.contactFullName
        }.\n`;
      }
      if (
        oldData.contactAddress?.mailingRegion !==
        newData.contactAddress?.mailingRegion
      ) {
        newModifiedString += `State modified from '${
          oldData.contactAddress?.mailingRegion
        }' to '${newData.contactAddress?.mailingRegion}' for contact id '${
          newData.contactId ? newData.contactId : oldData.contactId
        }', ${
          newData.contactFullName
            ? newData.contactFullName
            : oldData.contactFullName
        }.\n`;
      }
      if (
        oldData.contactAddress?.mailingPostalCode !==
        newData.contactAddress?.mailingPostalCode
      ) {
        newModifiedString += `Postal Code modified from '${
          oldData.contactAddress?.mailingPostalCode
        }' to '${newData.contactAddress?.mailingPostalCode}' for contact id '${
          newData.contactId ? newData.contactId : oldData.contactId
        }', ${
          newData.contactFullName
            ? newData.contactFullName
            : oldData.contactFullName
        }.\n`;
      }
      return newModifiedString;
    };

    return (
      <Modal
        title={"Edit Name and Address"}
        style={{ top: "5%", height: "90%", padding: 0 }}
        width={600}
        open={isOpenModal}
        footer={null}
        onCancel={() => {
          dismissModal();
        }}
      >
        <Spin spinning={isUpdating}>
          <Form
            form={form}
            variant="outlined"
            layout="vertical"
            initialValues={addContactInfo}
            labelCol={{ span: 24 }}
            style={{
              width: "100%",
              padding: 8,
            }}
            onValuesChange={(changedValues, allValues) =>
              setAddContactInfo(allValues)
            }
            // validateMessages={validateMessages}
            // requiredMark={customizeRequiredMark}
            autoComplete="off"
            scrollToFirstError={{ block: "center", inline: "nearest" }}
          >
            <Row>
              <Col span={24}>
                <Form.Item<ContactInformation> name="accountNumber" hidden>
                  <Input />
                </Form.Item>
                <Form.Item<ContactInformation> name="id" hidden>
                  <Input />
                </Form.Item>
                <Form.Item<ContactInformation> name="user" hidden>
                  <Input />
                </Form.Item>
                <Form.Item<ContactInformation> name="employeeKey" hidden>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item<ContactInformation>
                  label="First Name"
                  name="firstName"
                  style={{ margin: 5 }}
                >
                  <Input disabled={props.contact.primaryOwner === "Yes"} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item<ContactInformation>
                  label="Last Name"
                  name="lastName"
                  style={{ margin: 5 }}
                >
                  <Input disabled={props.contact.primaryOwner === "Yes"} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item<ContactInformation>
                  label="Address"
                  name={["contactAddress", "mailingAddress1"]}
                  style={{ margin: 5 }}
                >
                  <Input disabled={!ricStore.isEditable} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item<ContactInformation>
                  label="Address 2"
                  name={["contactAddress", "mailingAddress2"]}
                  style={{ margin: 5 }}
                >
                  <Input disabled={!ricStore.isEditable} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item<ContactInformation>
                  label="City"
                  name={["contactAddress", "mailingCity"]}
                  style={{ margin: 5 }}
                >
                  <Input disabled={!ricStore.isEditable} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item<ContactInformation>
                  label="State"
                  name={["contactAddress", "mailingRegion"]}
                  style={{ margin: 5 }}
                >
                  <Select
                    options={usStates.map((el, index) => {
                      return {
                        value: el.code,
                        label: `${el.code} | ${el.name}`,
                      };
                    })}
                    disabled={!ricStore.isEditable}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item<ContactInformation>
                  label="Zip Code"
                  name={["contactAddress", "mailingPostalCode"]}
                  style={{ margin: 5 }}
                  rules={[{ min: 5, max: 7 }]}
                >
                  <Input disabled={!ricStore.isEditable} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24} style={{ textAlign: "right", marginTop: "16px" }}>
                <Space>
                  <Button
                    size="middle"
                    onClick={() => {
                      dismissModal();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    size="middle"
                    type="primary"
                    htmlType="submit"
                    onClick={async () => {
                      setIsUpdating(true);
                      ricStore.setIsLoadingApp(true);
                      console.log(addContactInfo);
                      ricStore
                        .updateNameAndAddress(
                          addContactInfo,
                          applicationStore.userEmployee.employeeKey,
                          applicationStore.userEmployee.employeeID,
                          getModifiedString(props.contact, addContactInfo)
                        )
                        .then(() => {
                          ricStore.getRicInfo().catch(console.log);
                        })
                        .catch(console.log);
                      dismissModal();
                    }}
                  >
                    Save
                  </Button>
                </Space>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
    );
  }
);
export default EditNameAddressForm;
