import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Form } from 'antd';

const CheckBoxButton = (props) => {
    const { options, name, label, disabled, checkedValues, onChange } = props;

    return (
        <Form.Item name={name}>
            <label>{label}</label>
            <Checkbox.Group
                options={options}
                value={checkedValues}
                onChange={onChange}
                disabled={disabled}
            />
        </Form.Item>
    );
};

CheckBoxButton.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.any
    })).isRequired,   
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    checkedValues: PropTypes.array,
    onChange: PropTypes.func.isRequired,
};

CheckBoxButton.defaultProps = {
    disabled: false,
    checkedValues: [],
};

export default CheckBoxButton;
