import React from 'react';
import { DatePicker, Form } from 'antd';
import PropTypes from 'prop-types'; // Import PropTypes

const DateTimePicker = (props) => {
  const { label, name, rules, size, disabled } = props;
  
  return (
    <Form.Item label={label} name={name} rules={rules}>
      <DatePicker disabledDate={disabled} size={size} style={{ width: '100%' }} />
    </Form.Item>
  );
};

DateTimePicker.propTypes = {
  label: PropTypes.string.isRequired, 
  name: PropTypes.string.isRequired, 
  rules: PropTypes.array, 
  size: PropTypes.oneOf(['small', 'middle', 'large']), 
  disabled: PropTypes.func, 
};

DateTimePicker.defaultProps = {
  size: 'middle', 
  disabled: () => false, 
};

export default DateTimePicker;
