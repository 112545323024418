/* eslint-disable */
import { observer } from "mobx-react-lite";
import {
  Button,
  Flex,
  Input,
  Modal,
  Popconfirm,
  Radio,
  RadioChangeEvent,
  Space,
  Spin,
  Typography,
} from "antd";
import { ReactElement, useEffect, useState } from "react";
import { useStore } from "../../../stores/root-store-context";
import React from "react";
//import { DisplayMode } from "@microsoft/sp-core-library";
import { EStatement } from "../../../stores/RicStore";

const EStatementModal = observer((): ReactElement => {
  const { ricStore, applicationStore } = useStore();

  const { Text } = Typography;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );

  const showModal = async (): Promise<void> => {
    setIsModalOpen(true);
    ricStore.clearEStatement();
    await ricStore.loadEStatement();
  };

  const handleCancel = (): void => {
    setIsModalOpen(false);
  };

  const handleOk = (): void => {
    setIsModalOpen(false);
  };

  const handleUpdate = async (): Promise<void> => {
    await ricStore.UpdateEStatement(applicationStore.userEmployee);
  };

  const handleChange = (e: RadioChangeEvent): void => {
    ricStore.setEStatementRadio(e.target.value);
  };

  return (
    <>
      <Button block onClick={showModal}>
        eStatement Sign Up
      </Button>
      <Modal
        title="eStatement Sign Up"
        open={isModalOpen}
        onOk={handleOk}
        cancelText="Close"
        okButtonProps={{ style: { display: "none" } }}
        onCancel={handleCancel}
        width={500}
        closable={false}
      >
        <>
          <Spin spinning={ricStore.eStatementLoading}>
            <>
              <Flex gap="middle" vertical>
                <Radio.Group value={ricStore.eStatementRadio}>
                  <Radio value={EStatement.Paper} onChange={handleChange}>
                    Paper statement (Send my statement for this account via
                    mail)
                  </Radio>
                  <Radio value={EStatement.Electronic} onChange={handleChange}>
                    Electronic statement (Send my statement for this account via
                    email)
                  </Radio>
                </Radio.Group>
                {ricStore.eStatementRadio === EStatement.Electronic ? (
                  <>
                    <Input
                      placeholder="Please enter email"
                      value={ricStore.eStatementData?.email}
                      status={errorMessage !== undefined ? "error" : ""}
                      onChange={(email) => {
                        if (
                          /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(email.target.value)
                        ) {
                          setErrorMessage(undefined);
                        } else {
                          setErrorMessage("Please Enter valid email address");
                        }
                        ricStore.setEStatementEmail(email.target.value);
                      }}
                    />
                    {errorMessage !== undefined ? (
                      <Text type="danger">{errorMessage}</Text>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}
                {ricStore.eStatementMessage !== "" ? (
                  <Text type="success">{ricStore.eStatementMessage}</Text>
                ) : (
                  <></>
                )}
                <Button
                  disabled={
                    errorMessage !== undefined &&
                    ricStore.eStatementRadio === EStatement.Electronic
                  }
                  onClick={handleUpdate}
                >
                  UPDATE PREFERENCES
                </Button>
              </Flex>
            </>
          </Spin>
        </>
      </Modal>
    </>
  );
});

export default EStatementModal;
