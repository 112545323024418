import React, { memo, useEffect } from "react";
import { Col, Form, Row } from "antd";
import Component from "Components";
import { useDispatch, useSelector } from "react-redux";
import { Community } from "../../../redux/Actions";
import Spinner from "Components/Spinner/Spiner";
import Validation from "utils/Validation";
import PropTypes from "prop-types";  

const { TextInput, SelectBox, Button } = Component;

const CommunityForm = ({ onCancel, users, community, HandleOnChange, isEdit, disabled }) => {
  const validationRules = Validation.getValidation([
    "communityId", "address", "phone", "residentConatactKey", "residentKey"
  ]);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const {  getCommunityById, loading } = useSelector((state) => state?.Communitys);

  useEffect(() => {
    if (getCommunityById) {
      const preservedFieldValue = form.getFieldValue("communityId");

      form.resetFields();
      form.setFieldsValue({ communityId: preservedFieldValue });

      form.setFieldsValue(getCommunityById);
    }
  }, [getCommunityById, form]);

  const onSearch = (value) => {
    console.log("search:", value);
  };

  const filterOption = (input, option) =>
    (option?.children ?? "").toLowerCase().includes(input.toLowerCase());

  const handleFinish = (values) => {
    const req = {
      Id: users?.id,
      CommunityId: values?.communityId,
      Address: values?.address,
      ResidentContactKey: values?.residentContactKey,
      Phone: values?.phone,
      ResidentKey: values?.residentKey
    };
    let allRequest = {
      request: req,
      handleRes: handleRes
    };
    dispatch(Community.EditCommunityByIdRequest(allRequest));
  };

  const handleRes = (data) => {
    if (data?.data) {
      onCancel();
    }
  };

  return (
    <>
      {loading && <Spinner />}
      <div style={{ background: '#ffffff', padding: '25px 30px 20px 30px' }}>
        <Form
          layout="vertical"
          className="ant-form"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValues={getCommunityById}
          onFinish={handleFinish}
          form={form}
        >
          <Row justify={'space-between'}>
            <Col sm={7}>
              <SelectBox
                options={community ?? []}
                className="user-form-input"
                name="communityId"
                placeholder="Community Name"
                showSearch={true}
                label="Community"
                onSearch={onSearch}
                filterOption={filterOption}
                size="md"
                rules={validationRules.communityId} hasFeedback={true} validateTrigger={"onChange"}
                onChange={HandleOnChange}
              />
            </Col>
            <Col sm={7}>
              <TextInput disabled={disabled} label="Address" name="address" placeholder="Address" rules={validationRules.address} hasFeedback={true} validateTrigger={"onChange"} />
            </Col>
            <Col sm={7}>
              <TextInput disabled={disabled} label="Phone" name="phone" placeholder="Phone" rules={validationRules.phone} hasFeedback={true} validateTrigger={"onChange"} />
            </Col>
            <Col sm={7}>
              <TextInput disabled={disabled} label="Resident Key" name="residentKey" placeholder="Resident Key" rules={validationRules.residentKey} hasFeedback={true} validateTrigger={"onChange"} />
            </Col>
            <Col sm={7}>
              <TextInput disabled={disabled} label="Resident Contact Key" name="residentContactKey" placeholder="Resident Contact Key" rules={validationRules.residentConatactKey} hasFeedback={true} validateTrigger={"onChange"} />
            </Col>
            <Col sm={24} className="create-button-content">
              {!disabled && <Button htmlType="submit" title={isEdit ? "Update" : "Create"} className="create-button" />}
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

// PropTypes validation
CommunityForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  users: PropTypes.object.isRequired, 
  community: PropTypes.array.isRequired, 
  HandleOnChange: PropTypes.func.isRequired, 
  isEdit: PropTypes.bool.isRequired, 
  disabled: PropTypes.bool.isRequired, 
};

export default memo(CommunityForm);
