import React from 'react';
import PropTypes from 'prop-types';
import { Result } from 'antd';

const Messages = (props) => { 
    const { status, title, subTitle, extra } = props;
  
    return (
        <Result
            className="custom-404-result" 
            status={status}
            title={title}
            subTitle={subTitle}
            extra={extra}
        />
    );
};

Messages.propTypes = {
    status: PropTypes.string.isRequired,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    subTitle: PropTypes.string,
    extra: PropTypes.node,
};

Messages.defaultProps = {
    subTitle: '',
    extra: null,
};

export default Messages;
