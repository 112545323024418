import React from 'react';
import { Tabs } from 'antd';
import PropTypes from 'prop-types';

const { TabPane } = Tabs;

const SlidingTabsDemo = ({ tabs }) => {
  return (
    <div>
      <Tabs defaultActiveKey="1" tabPosition="top" style={{ height: 220 }}>
        {tabs.map((tab, index) => (
          <TabPane tab={tab.title} key={index}>
            {tab.content}
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
};

SlidingTabsDemo.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      content: PropTypes.node.isRequired,
    })
  ).isRequired,
};

export default SlidingTabsDemo;
