/* eslint-disable */
export const allProperiesOption: Properties = {
  id: 0,
  name: "All",
  titleInDropdown: "All",
};

export type Properties = {
  id: number;
  name: string;
  titleInDropdown: string;
}

export type UserEmployee = {
  employeeID: string;
  employeeKey: string;
  employeeName: string
}

export type ConfigApi = {
  domain: string;
  xApiKey: string;
  userEmail: string;
};
