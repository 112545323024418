import { makeAutoObservable } from "mobx";
import { ConfigApi } from "../api/base/ConfigApi";
import RicApiClient from "../api/RicApi";


class ApiStore {
  RicApiClient!: RicApiClient;
  configApi: ConfigApi = {} as ConfigApi;
  constructor(configApi: ConfigApi) {
    makeAutoObservable(this);
    this.configApi = configApi;
    this.RicApiClient = new RicApiClient(configApi);
  }
}
export default ApiStore;
