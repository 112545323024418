/* eslint-disable */
import { Col, Form, notification, Row } from "antd";
import Component from "Components";
import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { Users } from "../../../redux/Actions";
import CommonFunctions from "utils/CommonFunction/CommonFunctions";
import Validation from "utils/Validation";
import { useNavigate, useParams } from "react-router-dom";
import TypographyComponent from "Components/Typography/Typography";

const { TextInput, Button, Spinner, CheckboxTable, Modal } =
  Component;

const CreateUser = ({ users, isEdit, disabled }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [userData, setUserData] = useState({});
  const [multipleUsers, setMultipleUsers] = useState([]);
  const [emailOpen, setEmailOpen] = useState(false);
  const { loading, CreateUser } = useSelector((state) => state?.GetAllUser);
  const [values, setValues] = useState({});
  const [updateValues,setUpdateValues] = useState({})
  const [selectedRowKeys, setSelectedRowKeys] = useState();
  const [foundEmailValues, setFoundEmailValues] = useState();
  const [blankRCK, setBlankRCK] = useState();
  const [blankCA, setBlankCA] = useState();
  const [blankCI, setBlankCI] = useState();
  const [emailselectedRowKeys, setEmailselectedRowKeys] = useState();
  
  const [selectedRows, setSelectedRows] = useState([]);
  const validationRules = Validation.getValidation([
    "firstName",
    "lastName",
    "role",
    "permissionId",
    "email",
    "city",
    "state",
    "zip",
    "residentKey",
    "phone",
    "streetAndUnitNumber",
  ]);
  const [form] = Form.useForm();
  // useEffect(()=>{
  //   if(getAllCommunitys?.length > 0){
  //     const updatedData = getAllCommunitys?.map((data, i) => ({
  //       ...data,
  //       key: i,
  //     }));
  //    setCommunityList(updatedData)
  //   }
  // },[getAllCommunitys])
  useEffect(() => {
    if (
      CreateUser?.data?.statusCode == 2 &&
      CreateUser?.data?.residentInfo?.length > 0
    ) {
      const updatedData = CreateUser?.data?.residentInfo?.map((data, i) => ({
        ...data,
        key: i,
      }));
      setMultipleUsers(updatedData);
    } else if (CreateUser?.data?.statusCode == 4) {
      setEmailOpen(false);
      dispatch(Users.CreateUserReset());
      notification["success"]({
        message: "User Successfully Created",
        description: "",
      });
      setMultipleUsers([]);
      form.resetFields();
      //data?.payload?.route('/AAM/user')
      Swal.fire({
        title: `${CreateUser?.data?.residentInfo[0]?.message} </br> password : ${CreateUser?.data?.password}`,
        showDenyButton: true,
        confirmButtonText: "Go View User Details",
        denyButtonText: "Create New User",
        confirmButtonColor: "rgb(92, 122, 147)",
        denyButtonColor: "rgb(112 111 111)",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          navigate(
            `/AAM/user/userDetails/${CreateUser?.data?.residentInfo[0]?.userId}`
          );
        }
         else if (result.isDismissed) {
          console.log('isDismissed')
        }
      });
    } else if (
      CreateUser?.data?.statusCode == 0 ||
      CreateUser?.data?.statusCode == 1 ||
      CreateUser?.data?.statusCode == 3 ||
      CreateUser?.data?.statusCode == 5 ||
      CreateUser?.data?.statusCode == 6
    ) {
      setEmailOpen(false);
      notification["warning"]({
        message: CreateUser?.data?.residentInfo[0]?.message,
        description: "",
      });

      //data?.payload?.route('/AAM/user')
    }
  }, [CreateUser]);
  useEffect(() => {
    if (users) {
      form.setFieldsValue(users);
    }
  }, [users, form]);
  const HandleOnChange = (e) => {
   // const { value, id } = e?.target;
    userData[e?.target?.id] = e?.target?.value;
    setUserData(userData);
    const number = CommonFunctions.formatPhoneNumber(e?.target?.value);
    if (id == "phone") {
      form.setFieldsValue({ phone: number });
    }

  };

  const OnFinish = (values) => {
    if (isEdit) {
     
      values.id = id;
      // values.ResidentKey = null;
      // values.ResidentContactKey = null;
      // values.CommunityId = null;
      // values.CommunityAddress = null;
      //values.IsActive
      let allRequest = {
        request: values,
        route: navigate,
      };

      dispatch(Users.UpdateUserRequest(allRequest));
    }else {
      let allRequest = {
        request:  values,
        route: navigate,
      };

    setValues(values);
    setSelectedRows([values]);
    
     
      dispatch(Users.CreateUserRequest(allRequest));
    }
  };
 

  const columns = [
    { key: "1", title: "First Name", dataIndex: "firstName" },
    { key: "2", title: "Last Name", dataIndex: "lastName" },
    { key: "3", title: "Email", dataIndex: "email" },
    { key: "4", title: "Resident Key", dataIndex: "residentKey" },
    {
      key: "5",
      title: "Resident Contact Key",
      dataIndex: "residentContactKey",
    },
    { key: "6", title: "Community Address", dataIndex: "communityAddress" },
    { key: "7", title: "Email", dataIndex: "email" },
    { key: "7", title: "Community Id", dataIndex: "communityId" },
    {
      key: "9",
      title: "Status",
      dataIndex: "isActive",
      render: (text) => (text ? "active" : "inActive"),
    },
  ];
  const columnsSelect = [{ title: "Email", dataIndex: "email" }];
  
  const onSelectChange = (newSelectedRowKeys, selectedRowss) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setFoundEmailValues(selectedRowss[0]?.email);
    setBlankRCK(selectedRowss[0]?.residentContactKey);
    setBlankCA(selectedRowss[0]?.communityAddress);
    setBlankCI(selectedRowss[0]?.communityId);
    let updatedRows;

    if (selectedRows.length === 0) {
      updatedRows = [selectedRowss[selectedRowss.length - 1]];
    } else {
      updatedRows = [selectedRows[0], selectedRowss[selectedRowss.length - 1]];
    }
    setSelectedRows(updatedRows);
    setEmailOpen(true);

    // if (newSelectedRowKeys?.length > 0) {
    //   let id = newSelectedRowKeys[0];
    // }
  };
  const onSelectChanges = (newSelectedRowKeys, selectedRowss) => {
    setEmailselectedRowKeys(newSelectedRowKeys);

    if (newSelectedRowKeys?.length > 0) {
      const newReq = {...values}; 

      // let id = newSelectedRowKeys[0];
      newReq.createUserEmail = selectedRowss[0]?.email;
      newReq.email = foundEmailValues;
      newReq.model = emailOpen;
      if (!selectedRowss[0]?.email) {
        newReq.BlankEmailVerifiedByUser = true;
        newReq.BlankEmailResidentKey = selectedRowss[0]?.residentKey;
        newReq.BlankEmailCommunityAddress = blankCA;
        newReq.BlankEmailResidentContactKey = blankRCK;
        newReq.BlankEmailCommunityId = blankCI;
        newReq.email = values.createUserEmail;
      } else {
        newReq.BlankEmailVerifiedByUser = false;
      }

      setUpdateValues(newReq);
    }
  };
  const rowSelection = {
    type: "radio",
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const rowSelectionForEmail = {
    type: "radio",
    emailselectedRowKeys,
    onChange: onSelectChanges,
  };

  const HandleEmailSelect = () => {
    return (
      <>
        <CheckboxTable
          rowSelection={rowSelectionForEmail}
          pagination={false}
          columns={columnsSelect}
          data={selectedRows}
        />
        <Button
          title={"Create"}
          className="create-button create-user"
          placehoder="First Name"
          onClick={() => OnFinish(updateValues)}
        />
      </>
    );
  };
 
  
  const HandleEmailSelectCancel = () => {
    setEmailOpen(false);
  };
  return (
    <>
      {loading && <Spinner />}
      <div style={{ background: "#ffffff", padding: "25px 30px 20px 30px" }}>
        <Form
          layout="vertical"
          className="ant-form"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValues={users}
          onFinish={OnFinish}
          form={form}
        >
          <Row justify={"space-between"}>
            <Col sm={7}>
              <TextInput
                onChange={HandleOnChange}
                disabled={disabled}
                label="First Name"
                name="firstName"
                placehoder="First Name"
                rules={validationRules.firstName}
                hasFeedback={true}
                validateTrigger={"onChange"}
              />
            </Col>
            <Col sm={7}>
              <TextInput
                onChange={HandleOnChange}
                disabled={disabled}
                label="Last Name"
                name="lastName"
                placehoder="Last Name"
                rules={validationRules.lastName}
                hasFeedback={true}
                validateTrigger={"onChange"}
              />
            </Col>
            <Col sm={7}>
              <TextInput
                onChange={HandleOnChange}
                disabled={disabled}
                label="Email"
                name="email"
                placehoder="Email"
                rules={validationRules.email}
                hasFeedback={true}
                validateTrigger={"onChange"}
              />
            </Col>
            {!isEdit && (
              <Col sm={7}>
                <TextInput
                  onChange={HandleOnChange}
                  disabled={disabled || isEdit}
                  label="Phone"
                  name="phone"
                  placehoder="Phone"
                  rules={validationRules.phone}
                  hasFeedback={true}
                  validateTrigger={"onChange"}
                />
              </Col>
            )}

            <Col sm={7}>
              <TextInput
                onChange={HandleOnChange}
                disabled={disabled}
                label="Street And Unit Number"
                name="streetAndUnitNumber"
                placehoder="Street And Unit Number"
                rules={validationRules.streetAndUnitNumber}
                hasFeedback={true}
                validateTrigger={"onChange"}
              />
            </Col>
            <Col sm={7}>
              <TextInput
                onChange={HandleOnChange}
                disabled={disabled}
                label="City"
                name="city"
                placehoder="City"
                rules={validationRules.city}
                hasFeedback={true}
                validateTrigger={"onChange"}
              />
            </Col>
            <Col sm={7}>
              <TextInput
                onChange={HandleOnChange}
                disabled={disabled}
                label="State"
                name="state"
                placehoder="State"
                rules={validationRules.state}
                hasFeedback={true}
                validateTrigger={"onChange"}
              />
            </Col>
            <Col sm={7}>
              <TextInput
                onChange={HandleOnChange}
                disabled={disabled}
                label="Zip"
                name="zip"
                placehoder="Zip"
                rules={validationRules.zip}
                hasFeedback={true}
                validateTrigger={"onChange"}
              />
            </Col>
            {!isEdit && (
              <Col sm={7}>
                <TextInput
                  onChange={HandleOnChange}
                  disabled={disabled || isEdit}
                  label="Resident Key"
                  name="residentKey"
                  placehoder="Resident Key"
                  hasFeedback={true}
                  validateTrigger={"onChange"}
                />
              </Col>
            )}
            

            <Col sm={24} className="create-button-content">
              {!disabled && (
                <Button
                  htmlType="submit"
                  title={isEdit ? "Update" : "Create"}
                  className="create-button"
                  placehoder="First Name"
                />
              )}
            </Col>
          </Row>
        </Form>
        {multipleUsers?.length > 0 && (
          <>
            <TypographyComponent
              level={4}
             
            >We found below users on VMS. Please choose who you are?</TypographyComponent>
            <CheckboxTable
              rowSelection={rowSelection}
              pagination={false}
              columns={columns}
              data={multipleUsers}
            />
          </>
        )}

        <Modal
          title="choose the email to create user."
          centered="center"
          open={emailOpen}
          // onOk={viewCommunityFormHandle}
          onCancel={HandleEmailSelectCancel}
          Cancel={HandleEmailSelectCancel}
          width={800} // Adjust width as needed
          bodyStyle={{ maxHeight: "500px", overflowY: "auto" }}
          loading={loading}
        
        >
          {HandleEmailSelect}
       </Modal>
      </div>
    </>
  );
};

export default memo(CreateUser);
