/* eslint-disable */
import { makeAutoObservable } from "mobx";
import ApiStore from "./ApiStore";
import { getMimeImageType } from "../components/ModalFileViewer/ModalFileViewer";
import FileHelper from "../components/RIC/helpers/FileHelper";

class ModalViewerStore {

    isOpenModal: boolean = false;
    urlModal: string = "";
    documentName: string = "";
    isLocalFileForIframe: boolean = false;

    private _api: ApiStore;

    constructor(api: ApiStore) {
        makeAutoObservable(this);
        this._api = api;
    }
    openModal = () => {
        this.isOpenModal = true
    }
    dissmissModal = () => {
        this.isOpenModal = false;
    }
    editUrlModal = (newUrlModal: string) => {
        this.urlModal = newUrlModal;
    }
    editDocumentName = (newDocumentName: string) => {
        this.documentName = newDocumentName;
    }
    editIsLocalFileForIframe = (status: boolean) => {
        this.isLocalFileForIframe = status;
    }

    openFileByData = (data: any, fileName: string) => {
        const byteArray: number[] = Array.from(atob(data), char => char.charCodeAt(0));
        const mimeIsImage = getMimeImageType(fileName);
        const isMobileDevice = /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
        if (mimeIsImage.mimeType.length && (!mimeIsImage.isPdf || (!isMobileDevice && mimeIsImage.isPdf))) {
            const blob = new Blob([new Uint8Array(byteArray)], { type: mimeIsImage.mimeType })
            const url = URL.createObjectURL(blob);
            this.editUrlModal(url);
            this.editDocumentName(fileName);
            this.editIsLocalFileForIframe(true);
            this.openModal();
        } else {
            FileHelper.DownloadFile(byteArray, fileName)
        }
    }
    openFileByUrl = (url: string, fileName: string) => {
        const mimeIsImage = getMimeImageType(fileName);
        const isMobileDevice = /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
        if (mimeIsImage.mimeType.length && (!mimeIsImage.isPdf || (!isMobileDevice && mimeIsImage.isPdf))) {
            this.editUrlModal(url)
            this.editDocumentName(fileName)
            this.editIsLocalFileForIframe(false);
            this.openModal();
        } else {
            FileHelper.DownloadFileByURL(url, fileName)
        }
    }
    openPageByUrl = (url: string, pageName: string) => {
        this.editUrlModal(url)
        this.editDocumentName(pageName)
        this.editIsLocalFileForIframe(false);
        this.openModal();
    }



}
export default ModalViewerStore