/* eslint-disable */
import { List, Tabs } from "antd";
import { ReactElement } from "react";
import ContactInfo from "./ContactInfo";
import MailArhive from "./MailArhive";
import AccountDetail from "./AccountDetail";
import Architectural from "./Architectural";
import Compliances from "./Compliance/Compliances";
import * as React from "react";
import { useStore } from "../../../stores/root-store-context";
import Notes from "./Notes/Notes";
import { observer } from "mobx-react-lite";
const TableAccountInfo = observer((): ReactElement => {
  const { ricStore } = useStore();

  return (
    <Tabs
      type="card"
      items={[
        {
          label: "Contact Info",
          key: "Contact Info",
          children: (
            <List
              dataSource={ricStore.contactInformation}
              renderItem={(item, index) => (
                <ContactInfo
                  key={`contactInfo_${item.contactId}`}
                  contactInfo={item}
                  isDelete={index > 0}
                />
              )}
            />
          ),
        },
        {
          label: "Account Detail",
          key: "Account Detail",
          children: <AccountDetail dataSource={ricStore.accountDetails} />,
        },
        {
          label: "Notes",
          key: "Notes",
          children: <Notes />,
        },
        {
          label: "Compliance Issues",
          key: "Compliance",
          children: <Compliances dataSource={ricStore.complianceInformation} />,
        },
        {
          label: "Architectural",
          key: "Architectural",
          children: (
            <Architectural
              architecturalData={ricStore.architecturalInformation}
            />
          ),
        },
        {
          label: "Mail Archive",
          key: "Mail Archive",
          children: (
            <MailArhive
              mailItems={ricStore.mailArchive}
              openFileCallback={(dataRecordId) => {
                return ricStore.getPdfByDataRecordId(dataRecordId);
              }}
            />
          ),
        },
      ]}
    />
  );
});
export default TableAccountInfo;
