/* eslint-disable */
import { observer } from "mobx-react-lite";
import SearchForm, { RicFilterList } from "./RIC/components/Search/SearchForm";
import { useEffect } from "react";
import { Col, ConfigProvider, Flex, Row, Space, Spin } from "antd";
import TableAccountInfo from "./RIC/components/TableAccountInfo";
import AccountInfoBlock from "./RIC/components/AccountInfo/AccountInfoBlock";
import "./ric.css";
import * as React from "react";
import { useStore } from "../stores/root-store-context";
import SelectProperty from "./RIC/components/Search/SelectProperty";
import AllSearch from "./RIC/components/Search/AllSearch";
import "../tmp.css";
import { ModalFileViewer } from "./ModalFileViewer/ModalFileViewer";
import { Properties } from "../api/base/ConfigApi";

const RIC = observer(() => {
  const { ricStore, applicationStore, modalViewerStore } = useStore();
  useEffect(() => {
    if (applicationStore.SelectedProperties?.id) {
      ricStore.getFilterList(applicationStore.SelectedProperties.id);
    }
  }, [applicationStore.SelectedProperties]);
  useEffect(
    () => () => {
      ricStore.setAccount(undefined);
    },
    []
  );
  useEffect(() => {
    if (ricStore.selectedAccount) {
      ricStore.getRicInfo().catch(console.log);
    } else {
      ricStore.setShowRicInfo(false);
    }
  }, [ricStore.selectedAccount]);

  return (
    <div className="ric">
      <ConfigProvider
        key="RicConfigProvider"
        theme={{
          token: {},
          components: {
            Tabs: {
              cardBg: "#5a7890",
              colorBgContainer: "#dedede",
              itemColor: "#ffffff",
              itemSelectedColor: "#000000",
              itemHoverColor: "#000000",
              itemActiveColor: "#000000",
            },
            Button: {
              primaryColor: "#ffffff",
              defaultColor: "#ffffff",
              colorBgContainer: "#5a7890",
              controlHeight: 38,
              borderRadius: 10,

              defaultHoverBg: "#dedede",
              defaultHoverColor: "#000000",
              defaultBorderColor: "#dedede",
              defaultActiveColor: "#ffffff",
              defaultHoverBorderColor: "#5a7890",
            },
            Table: {
              rowHoverBg: "rgb(218, 221, 225)",
            },
          },
        }}
      >
        <Spin spinning={applicationStore.isLoading}>
          {/* <Flex justify="space-between" wrap="wrap" align="center" gap="middle" style={{ padding: 10 }}>
                    <div style={{ display: "flex", width: "100%", flexWrap: "wrap", justifyContent: "space-between" }}>
                        <Space style={{ flexWrap: "wrap" }}>
                            <h2 style={{ width: "280px" }}>RIC (Resident Info)</h2>
                        </Space>
                    </div>
                </Flex> */}
          <SelectProperty />
          {applicationStore.SelectedProperties?.id === 0 ? (
            <AllSearch />
          ) : (
            <></>
          )}
          {applicationStore.SelectedProperties?.id > 0 ? (
            <Spin spinning={ricStore.isLoadingApp || ricStore.isLoadingRicData}>
              <SearchForm
                item={ricStore.filterList}
                selectedAccount={ricStore?.selectedAccount}
                selectCallBack={(item) => {
                  ricStore.setAccount(item);
                }}
                resetCallBack={() => {
                  ricStore.setAccount(undefined);
                  applicationStore.setSearchTextProperty("");
                  applicationStore.setSelectedProperties({} as Properties);
                }}
              />
            </Spin>
          ) : (
            <></>
          )}
          {ricStore.isShowRicInfo === true ? (
            <div>
              <Row>
                <Col span={24}>
                  <AccountInfoBlock />
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <TableAccountInfo />
                </Col>
              </Row>
            </div>
          ) : (
            <></>
          )}
          <ModalFileViewer
            isOpenModal={modalViewerStore.isOpenModal}
            dismissModal={modalViewerStore.dissmissModal}
            urlModal={modalViewerStore.urlModal}
            isImage={modalViewerStore.isLocalFileForIframe}
            documentName={modalViewerStore.documentName}
          />
        </Spin>
      </ConfigProvider>
    </div>
  );
});

export default RIC;
